import React, { useCallback, useMemo } from 'react';

import { makeStyles } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import { FormattedMessage } from 'localization';

import OVCButton from './CustomerArea/OVCButton';
import OVAlert from './OVAlert';
import OVButton from './OVButton';
import useIsMounted from './hooks/useIsMounted';
import useSingleLoading from './hooks/useSingleLoading';
import { TrackingStep } from './types/Tracking';

const useStyles = makeStyles({
  container: {
    width: '100%',
    marginTop: '10px',
    marginBottom: '20px',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    '& > *': {
      marginBottom: '4px',

      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
});

const shouldDisplay = (steps: unknown) => {
  if (
    Array.isArray(steps) &&
    steps.length > 0 &&
    steps.some((step) => (step?.status || '').toUpperCase() === 'EXCEPTION')
  ) {
    return true;
  }

  return false;
};
interface ITrackingExceptionSelfService {
  steps: TrackingStep[];
  exceptionColor?: string;
  requestFunc: any;
  otn: string;
  apiUrl: string;
  lang: string;
  isCustomer: boolean;
  noAction?: boolean;
}

const TrackingExceptionSelfService = ({
  steps,
  exceptionColor,
  requestFunc,
  apiUrl,
  otn,
  lang,
  isCustomer = false,
  noAction = false,
}: ITrackingExceptionSelfService) => {
  const isMounted = useIsMounted();
  const classes = useStyles();

  const isDisplaying = useMemo(() => shouldDisplay(steps), [steps]);
  const btnStyle = useMemo(
    () =>
      isCustomer
        ? { fontSize: '14px' }
        : {
            backgroundColor: exceptionColor,
            borderColor: exceptionColor,
            color: '#fff',
            textDecoration: 'none',
          },
    [exceptionColor, isCustomer],
  );

  const [isLoading, error, courierUrl, setLoading, setError, setCourierUrl] =
    useSingleLoading<string>(false);

  const handleClick = useCallback(
    (e: any) => {
      e.preventDefault();

      if (noAction) {
        return;
      }

      if (courierUrl) {
        window.open(courierUrl, '_blank');
        return;
      }

      setLoading(true);
      requestFunc(`${apiUrl}/courier/public-tracking-link/${otn}`, {
        method: 'GET',
        params: { lang },
      })
        .then((res: any) => {
          if (isMounted.current) {
            setCourierUrl(res.data);
            setLoading(false);
            window.open(res.data, '_blank');
          }
        })
        .catch((err: any) => {
          if (isMounted.current) {
            setError(err.message || err.toString());
            setLoading(false);
          }
        });
    },
    [courierUrl, requestFunc, otn, noAction],
  );

  if (isDisplaying) {
    return (
      <div className={classes.container}>
        {isCustomer ? (
          <OVCButton
            icon={<LaunchIcon color="inherit" fontSize="inherit" />}
            iconOnRight
            style={btnStyle}
            onClick={handleClick}
            showLoader={isLoading}
            disabled={isLoading}
            useAlert
          >
            <FormattedMessage id="general.tracking.manage-exception" />
          </OVCButton>
        ) : (
          <OVButton
            icon={<LaunchIcon color="inherit" fontSize="inherit" />}
            iconOnRight
            style={btnStyle}
            onClick={handleClick}
            showLoader={isLoading}
            disabled={isLoading}
          >
            <FormattedMessage id="general.tracking.manage-exception" />
          </OVButton>
        )}

        <OVAlert isOpen={error !== null}>{error}</OVAlert>
      </div>
    );
  }

  return null;
};

export default TrackingExceptionSelfService;
