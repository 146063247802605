export default {
  'general.lang.ca': 'Catalán',
  'general.lang.de': 'Alemán',
  'general.lang.ee': 'Estonio',
  'general.lang.en': 'Inglés',
  'general.lang.es': 'Español',
  'general.lang.et': 'Estonio',
  'general.lang.fi': 'Finlandés',
  'general.lang.fr': 'Francés',
  'general.lang.it': 'Italiano',
  'general.lang.lt': 'Lituano',
  'general.lang.lv': 'Letón',
  'general.lang.pl': 'Polaco',
  'general.lang.pt': 'Portugués',
  'general.back': 'ATRÁS',
  'general.required': 'Requerido',
  'general.PRODUCT_PACKAGE_BROKEN': 'Caja/Embalaje del producto roto',
  'general.PRODUCT_BROKEN': 'Producto roto',
  'general.PRODUCT_USED': 'Producto usado',
  'general.PRODUCT_DIRTY': 'Producto sucio',
  'general.MISSING_PRODUCT_LABEL': 'No está la etiqueta del producto',
  'general.PRODUCT_MISSING': 'Producto no se encuentra en la devolución',
  'general.OTHER': 'Otro',
  'general.credit-card': 'Tarjeta de Crédito',
  'general.bank-transfer': 'Transferencia bancaria',
  'general.cash-on-delivery': 'Pago contra reembolso',
  'general.continue': 'Continuar',
  'general.CLOSED': 'CERRADO',
  'general.PARTIALLY_RECEIVED': 'PARCIALMENTE RECIBIDO',
  'general.RECEIVED': 'RECIBIDO',
  'general.IN_TRANSIT': 'EN TRÁNSITO',
  'general.comments': 'Comentarios',
  'general.return': 'Devolver',
  'general.user-account': 'Cuenta de Usuario',
  'general.sign-out': 'Desconectarse',
  'general.accept': 'Aceptar',
  'general.was-returned-on': 'Fue devuelto el',
  'general.product-cannot-returned': 'Este producto no puede ser devuelto',
  'general.product-non-returnable':
    'Este producto no se puede devolver. Contacta con atención al cliente si deseas devolverlo.',
  'general.can-return-it': 'Puedes devolverlo hasta el',
  'general.created': 'CREADO',
  'general.shipped': 'ENVIADO',
  'general.in-transit': 'EN TRÁNSITO',
  'general.out-of-delivery': 'EN REPARTO',
  'general.delivered': 'ENTREGADO',
  'general.shipping-charges': 'GASTOS DE ENVÍO',
  'general.order-details': 'Detalle del pedido',
  'general.return-details': 'Detalles devolución',
  'general.no-orders-in-transit': 'No tiene pedidos en tránsito actualmente',
  'general.products-returned': 'Productos a devolver',
  'general.select-motive': 'Selecciona un motivo',
  'general.why-want-return': '¿Por qué quieres devolver este producto?',
  'general.do-have-comments': '¿Quieres añadir algún comentario?',
  'general.enter-comment': 'Introduce tu comentario',
  'general.do-want-attach-image': '¿Quieres adjuntar alguna imagen del producto?',
  'general.pickup-address': 'Dirección de recogida',
  'general.date-time-pickup': 'Fecha y hora de recogida',
  'general.contact-phone-number': 'Teléfono de contacto',
  'general.comments-courier': 'Comentarios para el repartidor',
  'general.confirm-address': 'Confirmo dirección de recogida:',
  'general.quantity': 'Cantidad',
  'general.length': 'Longitud',
  'general.width': 'Ancho',
  'general.height': 'Alto',
  'general.identifier': 'Identificador',
  'general.tracking-number': 'Numero de seguimiento',
  'general.date-purchase': 'Fecha de compra',
  'general.date-time': 'Fecha y hora',
  'general.status': 'Estado',
  'general.shipping-cost': 'Gastos de envío para esta devolución',
  'general.free': 'Gratis',
  'general.done': 'Hecho',
  'general.spanish': 'Español',
  'general.english': 'Inglés',
  'returns.step.products.title': 'SELECCIÓN DE PRODUCTO',
  'returns.step.products.text': 'Selecciona los productos a devolver',
  'returns.step.method.title': 'MÉTODO DE DEVOLUCIÓN',
  'returns.step.method.text': 'Elige tu método de devolución',
  'returns.step.instructions.title': 'INSTRUCCIONES',
  'returns.step.instructions.text': 'Prepara tu devolución',
  'returns.step.confirm.title': 'CONFIRMACIÓN',
  'returns.step.confirm.text': 'Devolución completada',
  'general.img-upload': 'Arrastra aquí o haz click para añadir imagen',
  'retcart.packages.text': `Completa los paquetes para hacer la devolución
    {lineBreak}{lineBreak}NUESTROS CONSEJOS:
    {lineBreak}- Intenta reusar el mismo embalaje donde has recibido el pedido. ¡Es bueno para el medioambiente y ya lo tienes ahí!
    {lineBreak}- Si devuelves más de un artículo, intenta usar el menor número de paquetes.
    {lineBreak}- Recuerda, cuando haces envíos, ¡el aire dentro del paquete también se envía! Si es posible, intenta usar una caja que minimice la cantidad de espacio vacío. Esto minimiza los costes de tu devolución.`,
  'retcart.step2.heading':
    'CONFIRMA EL TAMAÑO DE TU PAQUETE (HAZ CLIC EN ‘AÑADIR PAQUETE’ SI NECESITAS MÁS DE UNO)',
  'retcart.pack.default-title.display': 'USAR EL PAQUETE ENTREGADO:',
  'retcart.pack.custom-title.display': 'USAR PAQUETE A MEDIDA',
  'retcart.pack.custom-title.edit': 'USAR PAQUETE A MEDIDA (en cm)',
  'retcart.pack.change-size': '(Cambiar tamaño)',
  'retcart.pack.remove': '(Quitar)',
  'retcart.pack.add': 'AÑADIR PAQUETE',
  'retcart.addrmodal.heading1': 'SELECCIONA UNA DE TUS DIRECCIONES GUARDADAS',
  'retcart.addrmodal.heading2': 'O INTRODUCE UNA NUEVA DIRECCION',
  'retcart.print.loading.heading': 'Estamos procesando tu devolución...',
  'retcart.print.loading.note':
    '¡No cierres esta ventana hasta que haya acabado el proceso de devolución!',
  'landing.email-sent':
    'Email enviado con éxito, por favor ve a tu bandeja de entrada y verifica tu email en los próximos 15 minutos.',
  'general.status.IN_TRANSIT': 'EN TRÁNSITO',
  'general.status.DELIVERED': 'ENTREGADO',
  'general.status.DELIVERED_POST_OFFICE': 'ENTREGADO',
  'general.status.DELIVERED_TO_PICKUP_POINT': 'ENTREGADO EN{lineBreak}PUNTO DE ENTREGA',
  'general.status.DELIVERED_TO_SHOP': 'ENTREGADO EN{lineBreak}TIENDA',
  'general.status.PARTIALLY_DELIVERED': 'PARCIALMENTE ENTREGADO',
  'general.status.PARTIALLY_DELIVERED.short': 'ENTREGADO PARC.',
  'general.status.DELAYED': 'RETRASADO',
  'general.status.RETURNED': 'DEVUELTO',
  'general.status.DELETED': 'BORRADO',
  'general.status.RECEIVED': 'RECIBIDO',
  'general.status.PARTIALLY_RECEIVED': 'PARCIALMENTE RECIBIDO',
  'general.status.PARTIALLY_RECEIVED.short': 'RECIBIDO PARC.',
  'general.status.CLOSED': 'CERRADO',
  'general.status.CREATED': 'CREADO',
  'general.status.PICKING_QUEUE': 'COLA DE PICKING',
  'general.status.SHIPPING_QUEUE': 'COLA DE ENVÍOS',
  'general.status.REFUNDED': 'REEMBOLSADO',
  'general.status.SHIPMENT_PICKUP': 'PEDIDO ENVIADO',
  'general.status.SHIPPED': 'ENVIADO',
  'general.status.PICKUP': 'ENVIADO',
  'general.status.IN_TRANSIT_DELAYED': 'EN TRÁNSITO RETRASADO',
  'general.status.EXCEPTION': 'EXCEPCIÓN',
  'general.status.OUT_FOR_DELIVERY': 'EN REPARTO',
  'general.status.RETURNED_TO_ORIGIN': 'DEVUELTO A ORIGEN',
  'general.status.CANCELLED': 'CANCELADO',
  'sidebar.orders-in-transit': 'PEDIDOS EN TRÁNSITO',
  'sidebar.my-orders': 'MIS PEDIDOS',
  'sidebar.returns': 'DEVOLUCIONES',
  'general.courier.gls_ireland': 'GLS Ireland',
  'general.courier.tnt_italy': 'TNT Italy',
  'general.courier.brt': 'BRT',
  'general.courier.bring': 'Bring',
  'general.courier.budbee': 'Budbee',
  'general.courier.cbl': 'CBL',
  'general.courier.correos': 'Correos Express',
  'general.courier.ctt': 'CTT',
  'general.courier.ctt_portugal': 'CTT Portugal',
  'general.courier.chornopost': 'Chronopost',
  'general.courier.deprecated': 'DEPRECATED',
  'general.courier.deutschepost': 'Deutsche Post',
  'general.courier.deutschepost_germany': 'Deutsche Post',
  'general.courier.dhl': 'DHL',
  'general.courier.dhl_sweden': 'DHL',
  'general.courier.dhlparcel': 'DHL Parcel',
  'general.courier.dhlparcel_portugal': 'DHL Parcel',
  'general.courier.dhlparcel_spain': 'DHL Parcel',
  'general.courier.dhlparcel_poland': 'DHL Parcel',
  'general.courier.dhlparcel_unitedkingdom': 'DHL Parcel',
  'general.courier.dhl_paket_germany': 'DHL Paket',
  'general.courier.directa': 'Directa',
  'general.courier.dpd': 'DPD',
  'general.courier.gordon': 'Gordon',
  'general.courier.dpd_france': 'DPD',
  'general.courier.dpd_germany': 'DPD',
  'general.courier.dpd_portugal': 'DPD',
  'general.courier.dpd_portugal_new': 'DPD',
  'general.courier.dpd_romania': 'DPD',
  'general.courier.dpd_poland': 'DPD',
  'general.courier.envialia': 'Envialia',
  'general.courier.external': 'External Courier',
  'general.courier.external-EXCHANGE': 'Exchange',
  'general.courier.fedex': 'FedEx',
  'general.courier.glovo': 'Glovo',
  'general.courier.gls': 'GLS OLD',
  'general.courier.glsnew': 'GLS',
  'general.courier.gls_italy': 'GLS',
  'general.courier.glsshipit': 'GLS ShipIt',
  'general.courier.hermes': 'Hermes',
  'general.courier.inpost': 'InPost',
  'general.courier.inpost_spain': 'InPost',
  'general.courier.beeasy_spain': 'Beeasy',
  'general.courier.internal': 'Internal',
  'general.courier.matkahuolto': 'Matkahuolto',
  'general.courier.mondialrelay': 'Mondial Relay',
  'general.courier.colissimo': 'Colissimo',
  'general.courier.mrw': 'MRW',
  'general.courier.nacex': 'Nacex',
  'general.courier.omniva': 'Omniva',
  'general.courier.outvio': 'Outvio',
  'general.courier.paack': 'Paack',
  'general.courier.parcelforce': 'Parcelforce',
  'general.courier.posti': 'Posti',
  'general.courier.publiccorreos': 'Correos',
  'general.courier.postnord': 'PostNord',
  'general.courier.postnl': 'PostNL',
  'general.courier.relaiscolis': 'Relais Colis',
  'general.courier.royalmail': 'Royal Mail',
  'general.courier.schenker': 'DB Schenker',
  'general.courier.sending': 'SENDING',
  'general.courier.seur': 'SEUR',
  'general.courier.seur_atlas': 'SEUR Atlas',
  'general.courier.shop': 'To Shop',
  'general.courier.smartpost': 'Smartpost',
  'general.courier.spring': 'Spring GDS',
  'general.courier.tipsa': 'TIPSA',
  'general.courier.tnt': 'TNT',
  'general.courier.transaher': 'Transaher',
  'general.courier.ups': 'UPS',
  'general.courier.venipak': 'Venipak',
  'general.courier.zeleris': 'Zeleris',
  'general.courier.zeleriscustom': 'Zeleris',
  'general.courier.zitycity': 'Zitycity',
  'general.trackingbar.datetime': 'Fecha y Hora',
  'general.trackingbar.location': 'Localización',
  'general.trackingbar.status': 'Estado',
  'general.trackingbar.detail': 'Detalle',
  'general.trackingbar.no-info':
    'La información de seguimiento para este pedido no está todavía disponible. Normalmente, tarda unas horas en actualizarse. Por favor, vuelve más tarde.',
  'general.return-error.all-returned': 'Se han devuelto todos los productos.',
  'retcart.heading.methods': '¿CÓMO QUIERES DEVOLVER LOS PRODUCTOS?',
  'retcart.method.toClientShop': 'LLEVA LA DEVOLUCIÓN A UNA DE NUESTRAS TIENDAS',
  'retcart.method.outvio': 'USA NUESTRO TRANSPORTISTA PARA HACER LA DEVOLUCIÓN',
  'retcart.method.ownCourier':
    'HAZ LA DEVOLUCIÓN POR TU CUENTA CON EL TRANSPORTISTA Y MÉTODO DE TU ELECCIÓN',
  'retcart.method.exchange': 'CAMBIA O REEMPLAZA LOS PRODUCTOS EN VEZ DE DEVOLVERLOS',
  'retcart.inst.toClientShop.heading':
    'CONFIRMA LA DEVOLUCIÓN Y LLEVA LOS PRODUCTOS A UNA DE NUESTRAS TIENDAS',
  'retcart.inst.toClientShop.text1': 'PARA FINALIZAR TU DEVOLUCIÓN haz Clic en el botón CONFIRMAR:',
  'retcart.inst.toClientShop.text2':
    'Crearemos una Hoja de Devolución con la que podrás devolver los productos a una de nuestras tiendas antes del {dueDate}.',
  'retcart.inst.toClientShop.text3':
    'Puedes encontrar un listado de nuestras tiendas aquí: {link}.',
  'retcart.inst.toClientShop.text3.link': 'lista de tiendas',
  'general.confirm': 'confirmar',
  'retcart.inst.ownCourier.heading':
    'CONFIRMA TU DEVOLUCIÓN Y ENVÍANOSLA CON EL TRANSPORTISTA DE TU ELECCIÓN',
  'retcart.inst.ownCourier.text1': 'PARA FINALIZAR TU DEVOLUCIÓN haz Clic en el botón CONFIRMAR:',
  'retcart.inst.ownCourier.text2':
    'Crearemos una Hoja de Devolución que deberás incluir en el paquete de devolución y enviarnos los productos antes del {dueDate}.',
  'retcart.inst.ownCourier.text3': 'Envia la devolución a:',
  'retcart.inst.ownCourier.text4':
    'PD: Puedes usar el transportista que quieras, y serás responsable de pagar por los gastos de envío. Recomendamos usar un transportista de confianza con localización de envío para asegurarte de que el envío nos llegue sin problemas.',
  'retcart.inst.exchange.heading': 'DINOS LOS DETALLES DEL CAMBIO Y CONFÍRMA',
  'retcart.inst.exchange.text1':
    '¿Por qué producto, talla/tamaño y cantidades quieres cambiar este producto?',
  'retcart.inst.exchange.text2': 'Incluye todos los detalles necesarios en el mensaje.',
  'retcart.inst.exchange.text.placeholder': 'Escribe los detalles del cambio aquí.',
  'retcart.inst.exchange.text3': `Para finalizar el cambio, haz clic en CONFIRMAR.

Hemos enviado un email con tu petición de cambio a nuestro departamento de atención al cliente y nos pondremos en contacto contigo para confirmar los detalles del cambio. Se ha enviado también una copia a tu dirección de email.`,
  'retcart.confirm-return-terms': 'He revisado mi devolución y aceptado los {terms}',
  'retcart.confirm-return-terms.terms': 'Términos y Condiciones',
  'retcart.confirm.heading': 'DEVOLUCIÓN PROCESADA',
  'retcart.confirm.text3': 'PARA ASEGURARTE DE QUE TODO VA PERFECTO CON TU DEVOLUCIÓN:',
  'retcart.confirm.toClientShop.text4':
    '1. Imprime la Hoja de Devolución y/o guarda la copia electrónica que enviamos por email.',
  'retcart.confirm.toClientShop.text5':
    '2. Lleva los productos a una de nuestras tiendas antes del {dueDate}.',
  'retcart.confirm.toClientShop.text6': '3. Lleva la Hoja de Devolución a la tienda contigo.',
  'retcart.download-return-sheet': 'DESCARGAR HOJA DE DEVOLUCIÓN',
  'retcart.download-label': 'DESCARGAR HOJA DE DEVOLUCIÓN Y ETIQUETA DE ENVÍO',
  'retcart.confirm.ownCourier.text1':
    '1. Imprime la Hoja de Devolución y ponla dentro del paquete junto a los artículos de la devolución.',
  'retcart.confirm.ownCourier.text2':
    '2. Envía la devolución con un transportista de tu confianza con servicio de localización de envío a:',
  'retcart.confirm.ownCourier.text3': '3. Asegurate de enviar la devolución antes del {dueDate}.',
  'retcart.confirm.outvio.text1':
    'TU DEVOLUCIÓN SE HA PROCESADO CON ÉXITO, DESCARGA LA ETIQUETA DE DEVOLUCIÓN HACIENDO CLIC EN EL BOTÓN DE ABAJO',
  'retcart.confirm.outvio.text2': '(la etiqueta se te ha enviado también por email):',
  'retcart.confirm.outvio.text3':
    '¡IMPORTANTE! PARA QUE TODO VAYA BIEN, ANTES DE QUE PASE A RECOGER EL TRANSPORTISTA, POR FAVOR:',
  'retcart.confirm.outvio.text4':
    '1. Imprime la Hoja de Devolución y recorta la etiqueta de envío.',
  'retcart.confirm.outvio.text5':
    '2. Introduce la Hoja de devolución en el paquete, ciérralo y péga la etiqueta de envío en el paquete.',
  'retcart.confirm.outvio.text6':
    '3. ¡Ya está!, relájate y espera a que el transportista pase a recoger la devolución en los próximos 2 días laborables.',
  'retcart.inst.headline.important': 'IMPORTANTE:',
  'retcart.inst.hasPickup.noLabel': `1. Revisa tu dirección y productos a devolver.{lineBreak}
      2. Tras darle al botón "CONFIRMAR", se generará una Hoja de Devolución.{lineBreak}
      3. Pon los artículos a devolver en el embalaje y ciérralo. {fillerOptionally} puedes añadir dentro del embalaje la Hoja de Devoluciones.{lineBreak}
      4. Una vez hagas clic en Confirmar, notificaremos al transportista y se pondrá en contacto contigo para acordar la recogida o pasará directamente a recoger en los 2 próximos días laborables. ¡IMPORTANTE! No tienes que imprimir etiqueta de envío. El transportista llevará una cuando pase a recoger la devolución.`,
  'retcart.inst.hasPickup.noLabel.fillerOptionally': 'Opcionalmente',
  'retcart.inst.hasPickup.byEmail': `1. Revisa tu dirección y productos a devolver.{lineBreak}
      2. Tras darle al botón “CONFIRMAR”, se generará una Hoja de Devolución y notificaremos al transportista para que pase a recoger. {fillerNotice}.{lineBreak}
      3. Una vez recibas la etiqueta de envío por email, pon los artículos a devolver en la(s) caja(s), adjunta la Hoja de Devolución, cierra la caja y pega la etiqueta de envío en la caja.{lineBreak}
      4. Un transportista vendrá a recoger la devolución en la dirección que has indicado en los próximos 2 días laborables.`,
  'retcart.inst.hasPickup.byEmail.fillerNotice':
    'El transportista te enviará la etiqueta por email a ({email}) en los próximos 2 días laborables.',
  'retcart.inst.hasPickup.provided': `1. Revisa tu dirección y productos a devolver.{lineBreak}
      2. Tras darle al botón “CONFIRMAR”, la(s) etiqueta(s) del envío se imprimirán, junto a la Hoja de Devolución, para cada paquete.{lineBreak}
      3. Pon los artículos a devolver en la(s) caja(s), adjunta la Hoja de Devolución, cierra la caja y pega la(s) etiqueta(s) de envío correspondiente(s) en cada caja.{lineBreak}
      4. El transportista vendrá a recoger la devolución en la dirección que has indicado en los próximos 2 días laborables.`,
  'retcart.inst.noPickup.byEmail': `1. Revisa los productos de tu devolución{lineBreak}
      2. Tras darle al botón “CONFIRMAR”, se generará una Hoja de Devolución. {fillerNotice}{lineBreak}
      3. Cuando recibas la etiqueta de envío por email, pon los artículos a devolver en la(s) caja(s), adjunta la Hoja de Devolución, cierra la caja y pega la etiqueta de envío correspondiente en cada caja.{lineBreak}
      4. Debes llevar la devolución a un punto de conveniencia u oficina del transportista. Para encontrar el más cercano, visita la web del transportista: {fillerCourierHomepage}.`,
  'retcart.inst.noPickup.byEmail.fillerNotice':
    ' Recibirás la etiqueta de envío en el email ({email}) en los próximos 2 días.',
  'retcart.inst.noPickup.byEmail.fillerCourierHomepage': 'aquí',
  'retcart.inst.noPickup.provided': `1. Revisa los productos de tu devolución{lineBreak}
      2. Tras darle al botón “CONFIRMAR”, la(s) etiqueta(s) del envío se generarán, junto a la Hoja de Devolución para cada paquete.{lineBreak}
      3. Pon los artículos a devolver en la(s) caja(s), recorta la etiqueta de envío, introduce la Hoja de Devolución en la caja, ciérrala y pega la etiqueta de envío correspondiente en la caja.{lineBreak}
      4. Debes llevar la devolución a un punto de conveniencia u oficina del transportista. Para encontrar el más cercano, visita la web del transportista: {fillerCourierHomepage}.`,
  'retcart.inst.noPickup.provided.fillerCourierHomepage': 'aquí',
  'retcart.confirm.outvio.head-sheet':
    'TU DEVOLUCIÓN SE HA PROCESADO CON ÉXITO, POR FAVOR DESCARGA LA HOJA DE DEVOLUCIÓN HACIENDO CLIC EN EL BOTÓN DE ABAJO.',
  'retcart.confirm.outvio.head-sheet.note':
    'Si no puedes ahora, no te preocupes, también te las hemos enviado a tu correo electrónico, para que las puedas imprimir en cualquier momento:',
  'retcart.confirm.outvio.head-label':
    'TU DEVOLUCIÓN SE HA PROCESADO CON ÉXITO, POR FAVOR DESCARGA LA HOJA DE DEVOLUCIÓN (CONTIENE LA ETIQUETA DE ENVÍO) HACIENDO CLIC EN EL BOTÓN DE ABAJO.',
  'retcart.confirm.outvio.head-label.note':
    '(la Hoja de Devolución con la etiqueta de envío se ha enviado también por email):',
  'retcart.confirm.outvio.head-label-by-email': 'YOUR RETURN HAS BEEN PROCESSED SUCCESSFULLY EN',
  'retcart.confirm.outvio.hasPickup.noLabel.head':
    '¡IMPORTANTE! PARA QUE TODO VAYA BIEN, ANTES DE QUE PASE A RECOGER EL TRANSPORTISTA, POR FAVOR:',
  'retcart.confirm.outvio.hasPickup.noLabel': `1. Imprime la Hoja de Devolución (opcional).{lineBreak}
      2. Introduce la Hoja de Devolución (si has decidido imprimirla) dentro de la caja y ciérrala ¡NO HAY ETIQUETA DE ENVÍO!.{lineBreak}
      3. Ya está. El transportista pasará a recoger el paquete en los próximos 2 días laborables y traerá consigo la etiqueta de envío.`,
  'retcart.confirm.outvio.hasPickup.byEmail.head':
    'PD: RECIBIRÁS LA ETIQUETA DE ENVÍO POR EMAIL EN LOS PRÓXIMOS 2 DÍAS LABORABLES.',
  'retcart.confirm.outvio.hasPickup.byEmail': `1. Una vez recibas la etiqueta de envío por email, imprímela y recórtala del resto de la Hoja de Devolución.{lineBreak}
      2. Introduce la Hoja de Devolución en el paquete junto con los artículos a devolver y pega la etiqueta de envío en el paquete, previamente cerrado.{lineBreak}
      3. ¡Ya está! El transportista pasará a recoger en los siguientes 2 días laborables.`,
  'retcart.confirm.outvio.hasPickup.provided': `1. Imprime la Hoja de Devolución y recorta la etiqueta de envío.{lineBreak}
      2. Introduce la Hoja de Devolución en el paquete, ciérralo y pega la etiqueta de envío en el paquete con los productos a devolver.{lineBreak}
      3. ¡Ya está! El transportista pasará a recoger en los siguientes 2 días laborables.`,
  'retcart.confirm.outvio.noPickup.byEmail': `1. Cuando recibas la Hoja de Devolución por email, imprímela y recorta la etiqueta de envío.{lineBreak}
      2. Introduce la Hoja de Devolución en el paquete junto con los artículos a devolver y pega la etiqueta de envío en el paquete, previamente cerrado.{lineBreak}
      3. Lleva la devolución a un punto de conveniencia u oficina del transportista. Para encontrar el más cercano, visita la web del transportista: {filler}.`,
  'retcart.confirm.outvio.noPickup.byEmail.filler': 'aquí',
  'retcart.confirm.outvio.noPickup.provided': `1. Imprime la Hoja de Devolución y recorta la etiqueta de envío.{lineBreak}
      2. Introduce la Hoja de Devolución en el paquete junto con los artículos a devolver y pega la etiqueta de envío en el paquete, previamente cerrado.{lineBreak}
      3. Lleva la devolución a un punto de conveniencia u oficina del transportista. Para encontrar el más cercano, visita la web del transportista: {filler}.`,
  'retcart.confirm.outvio.noPickup.provided.filler': 'aquí',
  'general.retcart.restart.btn': 'Empezar proceso de devolución de nuevo',
  'general.heading.refund.methods': '¿CÓMO QUIERES RECIBIR TU REEMBOLSO?',
  'retcart.method.SAME_AS_PAYMENT': 'REEMBOLSAR AL MÉTODO DE PAGO ORIGINAL',
  'retcart.method.CREDIT_IN_STORE': 'CRÉDITO DE LA TIENDA PARA FUTURAS COMPRAS',
  'retcart.confirm.exchange.heading': 'PETICIÓN DE CAMBIO COMPLETADA',
  'retcart.confirm.exchange.text1': 'TU PETICIÓN DE CAMBIO SE HA RECIBIDO CORRECTAMENTE.',
  'retcart.confirm.exchange.text2':
    '(Hemos enviado una copia a tu email con los detalles del cambio).',
  'retcart.confirm.exchange.text3':
    'Nos pondremos en contacto contigo en breve para confirmar los detalles del cambio.',
  'retcart.confirm.exchange.text4': '¡GRACIAS!',
  'general.cancel': 'Cancelar',
  'general.retcart.init-restart': 'Si el problema persiste, empieza de nuevo haciendo clic AQUÍ',
  'retcart.return-method.toClientShop': 'EN TIENDA',
  'retcart.return-method.outvio': 'NUESTRO TRANSPORTISTA',
  'retcart.return-method.ownCourier': 'TRANSPORTISTA PROPIO',
  'retcart.return-method.exchange': 'CAMBIO / REEMPLAZO',
  'retcart.return-method.SAME_AS_PAYMENT': 'REEMBOLSO',
  'retcart.return-method.CREDIT_IN_STORE': 'CRÉDITO TIENDA',
  'retcart.return-method.pickup': 'RECOGIDA',
  'retcart.return-method.dropoff': 'LLEVAR A PUNTO',
  'general.heading.pickup.methods':
    '¿QUIERES LLEVAR EL PAQUETE A UN PUNTO DE CONVENIENCIA O PEDIR UNA RECOGIDA?',
  'retcart.method.pickup': 'QUIERO QUE EL TRANSPORTISTA PASE A RECOGER LA DEVOLUCIÓN',
  'retcart.method.dropoff':
    'LLEVARÉ LA DEVOLUCIÓN A UN PUNTO DE CONVENIENCIA U OFICINA DEL TRANSPORTISTA',
  'retcart.img-attach-desc': 'Sube fotos del embalaje, producto y etiqueta de envío',
  'retcart.confirm.outvio.noPickup.noLabel.head':
    '¡IMPORTANTE! PARA QUE TODO VAYA BIEN, ANTES DE LLEVAR EL PAQUETE AL PUNTO DE ENTREGA, POR FAVOR:',
  'retcart.title.pickup': 'con recogida',
  'retcart.title.dropoff': 'llevar a punto',
  'general.tracking.manage-exception': 'Gestionar incidencia en la web del transportista',
  'general.error.id-ver-token-missing':
    'Se necesita el Token para autentificación. Comprueba el email de autentificación enviado.',
  'general.error.id-ver.token-missing':
    'Error en inicialización. Asegúrate de que tu navegador está actualizado.',
  'general.error.fileupload.file-too-large': 'Tamaño de fichero máximo {size} MB',
  'general.error.fileupload.too-many-files': 'Número de máximo de ficheros {number}',
  'general.error.fileupload.file-invalid-type': 'Tipo de fichero inválido',
  'general.error.fileupload.incorrect.size.max':
    'La resolución de la imagen debe ser como máximo {width} por {height}',
};
