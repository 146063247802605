export default {
  'general.lang.ca': 'Catalan',
  'general.lang.de': 'German',
  'general.lang.ee': 'Estonian',
  'general.lang.en': 'English',
  'general.lang.es': 'Spanish',
  'general.lang.et': 'Estonian',
  'general.lang.fi': 'Finnish',
  'general.lang.fr': 'French',
  'general.lang.it': 'Italian',
  'general.lang.lt': 'Lithuanian',
  'general.lang.lv': 'Latvian',
  'general.lang.pl': 'Polish',
  'general.lang.pt': 'Portuguese',
  'general.back': 'BACK',
  'general.required': 'Required',
  'general.PRODUCT_PACKAGE_BROKEN': 'Product package broken',
  'general.PRODUCT_BROKEN': 'Product broken',
  'general.PRODUCT_USED': 'Product used',
  'general.PRODUCT_DIRTY': 'Product dirty',
  'general.WRONG_PRODUCT_RETURNED': 'Wrong product received',
  'general.MISSING_PRODUCT_LABEL': 'Product lable missing',
  'general.PRODUCT_MISSING': 'Product missing',
  'general.OTHER': 'Other',
  'general.credit-card': 'Credit Card',
  'general.bank-transfer': 'Bank Transfer',
  'general.cash-on-delivery': 'Cash on Delivery',
  'general.continue': 'Continue',
  'general.CLOSED': 'CLOSED',
  'general.PARTIALLY_RECEIVED': 'PARTIALLY RECEIVED',
  'general.RECEIVED': 'RECEIVED',
  'general.IN_TRANSIT': 'IN TRANSIT',
  'general.comments': 'Comments',
  'general.return': 'Return',
  'general.user-account': 'User Account',
  'general.sign-out': 'Sign Out',
  'general.accept': 'Accept',
  'general.was-returned-on': 'It was returned on',
  'general.product-cannot-returned': 'This product cannot be returned',
  'general.product-non-returnable':
    'This product cannot be return. Please contact customer support if you wish to return it',
  'general.can-return-it': 'You can return it until',
  'general.created': 'CREATED',
  'general.shipped': 'SHIPPED',
  'general.in-transit': 'IN TRANSIT',
  'general.out-of-delivery': 'OUT FOR DELIVERY',
  'general.delivered': 'DELIVERED',
  'general.shipping-charges': 'SHIPPING CHARGES',
  'general.order-details': 'Order details',
  'general.return-details': 'Return details',
  'general.no-orders-in-transit': 'No orders currently in transit',
  'general.products-returned': 'Products to be returned',
  'general.select-motive': 'Select a reason',
  'general.why-want-return': 'Why do you want to return this product?',
  'general.do-have-comments': 'Do you have any comments?',
  'general.enter-comment': 'Enter your comment',
  'general.do-want-attach-image': 'Would you like to attach any images?',
  'general.pickup-address': 'Pickup address',
  'general.date-time-pickup': 'Date and Time of Pickup',
  'general.contact-phone-number': 'Contact phone number',
  'general.comments-courier': 'Comment for the courier',
  'general.confirm-address': 'I confirm the pickup address:',
  'general.quantity': 'Quantity',
  'general.length': 'Length',
  'general.width': 'Width',
  'general.height': 'Height',
  'general.identifier': 'Identifier',
  'general.tracking-number': 'Tracking number',
  'general.date-purchase': 'Date of purchase',
  'general.date-time': 'Date time',
  'general.status': 'Status',
  'general.shipping-cost': 'Shipping cost for this return',
  'general.free': 'Free',
  'general.done': 'Done',
  'general.spanish': 'Spanish',
  'general.english': 'English',
  'returns.step.products.title': 'PRODUCT SELECTION',
  'returns.step.products.text': 'Select products to return',
  'returns.step.method.title': 'RETURN METHOD',
  'returns.step.method.text': 'Choose your return method',
  'returns.step.instructions.title': 'INSTRUCTIONS',
  'returns.step.instructions.text': 'Prepare your return',
  'returns.step.confirm.title': 'CONFIRMATION',
  'returns.step.confirm.text': 'Return completed',
  'general.img-upload': 'Drag and drop or click to add an image',
  'retcart.packages.text': `Complete the packages to finish your return
    {lineBreak}{lineBreak}OUR TIPS:
    {lineBreak}- Try re-using the same package that was used to deliver your order. It is good for the planet and it is readily available!
    {lineBreak}- If you return more than one item, try using the least amount of packages.
    {lineBreak}- Remember, when you ship stuff, the air inside the package is shipped as well! If possible and safe, try using a box that minimizes empty space. This lowers your return costs.`,
  'retcart.step2.heading':
    'CONFIRM YOUR PACKAGE SIZE (CLICK ‘ADD PACKAGE’ IF YOU NEED TO USE MORE THAN ONE)',
  'retcart.pack.default-title.display': 'USE THE DELIVERY PACKAGE:',
  'retcart.pack.custom-title.display': 'USE A CUSTOM PACKAGE',
  'retcart.pack.custom-title.edit': 'USE A CUSTOM PACKAGE (size in cm)',
  'retcart.pack.change-size': '(Change size)',
  'retcart.pack.remove': '(Remove)',
  'retcart.pack.add': 'ADD PACKAGE',
  'retcart.addrmodal.heading1': 'SELECT ANY OF YOUR SAVED ADDRESSES',
  'retcart.addrmodal.heading2': 'OR INPUT A NEW ADDRESS',
  'retcart.print.loading.heading': 'We are processing your return...',
  'retcart.print.loading.note': 'Do not close this window until we process your request!',
  'landing.email-sent':
    'Email sent successfully, please check your inbox to verify your email within the next 15 minutes.',
  'general.status.BAD_ADDRESS': 'BAD ADDRESS',
  'general.status.CANCELLED': 'CANCELLED',
  'general.status.CLOSED': 'CLOSED',
  'general.status.CREATED': 'CREATED',
  'general.status.DAMAGED': 'DAMAGED',
  'general.status.DELAYED': 'DELAYED',
  'general.status.DELETED': 'DELETED',
  'general.status.DELIVERED': 'DELIVERED',
  'general.status.DELIVERED_CITYPAQ': 'DELIVERED TO{lineBreak}PICKUP POINT',
  'general.status.DELIVERED_PO_BOX': 'DELIVERED TO{lineBreak}PICKUP POINT',
  'general.status.DELIVERED_POST_OFFICE': 'DELIVERED TO{lineBreak}PICKUP POINT',
  'general.status.DELIVERED_TO_PICKUP_POINT': 'DELIVERED TO{lineBreak}PICKUP POINT',
  'general.status.DELIVERED_TO_SHOP': 'DELIVERED TO{lineBreak}SHOP',
  'general.status.DESTROYED': 'DESTROYED',
  'general.status.EXCEPTION': 'EXCEPTION',
  'general.status.IMPORTANT_INCIDENTS': 'IMPORTANT INCIDENT',
  'general.status.IN_TRANSIT': 'IN TRANSIT',
  'general.status.IN_TRANSIT_DELAYED': 'IN TRANSIT DELAYED',
  'general.status.LOST': 'LOST',
  'general.status.MISSING_DOCUMENTATION': 'MISSING DOCUMENTATION',
  'general.status.NOT_AT_HOME': 'NOT AT HOME',
  'general.status.OTHER_INCIDENTS': 'OTHER INCIDENT',
  'general.status.OUT_FOR_DELIVERY': 'OUT FOR DELIVERY',
  'general.status.PARTIALLY_DELIVERED': 'PARTIALLY DELIVERED',
  'general.status.PARTIALLY_DELIVERED.short': 'DELIVERED PART.',
  'general.status.PARTIALLY_RECEIVED': 'PARTIALLY RECEIVED',
  'general.status.PARTIALLY_RECEIVED.short': 'RECEIVED PART.',
  'general.status.PICKED_UP': 'PICKUP',
  'general.status.PICKING_QUEUE': 'PICKING LIST',
  'general.status.PICKUP': 'PICKUP',
  'general.status.RECEIVED': 'RECEIVED',
  'general.status.REFUNDED': 'REFUNDED',
  'general.status.REFUSED': 'REFUSED',
  'general.status.RETURNED': 'RETURNED',
  'general.status.RETURNED_TO_ORIGIN': 'RETURNED TO ORIGIN',
  'general.status.SHIPMENT_CREATED': 'SHIPMENT CREATED',
  'general.status.SHIPMENT_PICKUP': 'SHIPMENT PICKUP',
  'general.status.SHIPPED': 'SHIPPED',
  'general.status.SHIPPING_QUEUE': 'SHIPPING QUEUE',
  'general.status.STOLEN': 'STOLEN',
  'sidebar.orders-in-transit': 'ORDERS IN TRANSIT',
  'sidebar.my-orders': 'MY ORDERS',
  'sidebar.returns': 'RETURNS',
  'general.courier.gls_ireland': 'GLS Ireland',
  'general.courier.tnt_italy': 'TNT Italy',
  'general.courier.brt': 'BRT',
  'general.courier.bring': 'Bring',
  'general.courier.budbee': 'Budbee',
  'general.courier.cbl': 'CBL',
  'general.courier.correos': 'Correos Express',
  'general.courier.ctt': 'CTT',
  'general.courier.ctt_portugal': 'CTT Portugal',
  'general.courier.chornopost': 'Chronopost',
  'general.courier.deprecated': 'DEPRECATED',
  'general.courier.deutschepost': 'Deutsche Post',
  'general.courier.deutschepost_germany': 'Deutsche Post',
  'general.courier.dhl': 'DHL',
  'general.courier.dhl_sweden': 'DHL',
  'general.courier.dhlparcel': 'DHL Parcel',
  'general.courier.dhlparcel_portugal': 'DHL Parcel',
  'general.courier.dhlparcel_spain': 'DHL Parcel',
  'general.courier.dhlparcel_poland': 'DHL Parcel',
  'general.courier.dhlparcel_unitedkingdom': 'DHL Parcel',
  'general.courier.dhl_paket_germany': 'DHL Paket',
  'general.courier.directa': 'Directa',
  'general.courier.dpd': 'DPD',
  'general.courier.gordon': 'Gordon',
  'general.courier.dpd_france': 'DPD',
  'general.courier.dpd_germany': 'DPD',
  'general.courier.dpd_portugal': 'DPD',
  'general.courier.dpd_portugal_new': 'DPD',
  'general.courier.dpd_romania': 'DPD',
  'general.courier.dpd_poland': 'DPD',
  'general.courier.envialia': 'Envialia',
  'general.courier.external': 'External Courier',
  'general.courier.external-EXCHANGE': 'Exchange',
  'general.courier.fedex': 'FedEx',
  'general.courier.glovo': 'Glovo',
  'general.courier.gls': 'GLS OLD',
  'general.courier.glsnew': 'GLS',
  'general.courier.gls_italy': 'GLS',
  'general.courier.glsshipit': 'GLS ShipIt',
  'general.courier.hermes': 'Hermes',
  'general.courier.inpost': 'InPost',
  'general.courier.inpost_spain': 'InPost',
  'general.courier.beeasy_spain': 'Beeasy',
  'general.courier.internal': 'Internal',
  'general.courier.matkahuolto': 'Matkahuolto',
  'general.courier.mondialrelay': 'Mondial Relay',
  'general.courier.colissimo': 'Colissimo',
  'general.courier.mrw': 'MRW',
  'general.courier.nacex': 'Nacex',
  'general.courier.omniva': 'Omniva',
  'general.courier.outvio': 'Outvio',
  'general.courier.paack': 'Paack',
  'general.courier.parcelforce': 'Parcelforce',
  'general.courier.posti': 'Posti',
  'general.courier.publiccorreos': 'Correos',
  'general.courier.postnord': 'PostNord',
  'general.courier.postnl': 'PostNL',
  'general.courier.relaiscolis': 'Relais Colis',
  'general.courier.royalmail': 'Royal Mail',
  'general.courier.schenker': 'DB Schenker',
  'general.courier.sending': 'SENDING',
  'general.courier.seur': 'SEUR',
  'general.courier.seur_atlas': 'SEUR Atlas',
  'general.courier.shop': 'To Shop',
  'general.courier.smartpost': 'Smartpost',
  'general.courier.spring': 'Spring GDS',
  'general.courier.tipsa': 'TIPSA',
  'general.courier.tnt': 'TNT',
  'general.courier.transaher': 'Transaher',
  'general.courier.ups': 'UPS',
  'general.courier.venipak': 'Venipak',
  'general.courier.zeleris': 'Zeleris',
  'general.courier.zeleriscustom': 'Zeleris',
  'general.courier.zitycity': 'Zitycity',
  'general.trackingbar.datetime': 'Date Time',
  'general.trackingbar.location': 'Location',
  'general.trackingbar.status': 'Status',
  'general.trackingbar.detail': 'Detail',
  'general.trackingbar.no-info':
    'Tracking information is not yet available for this order. Usually, it takes a few hours to get updated. Please, check back later.',
  'general.return-error.all-returned': 'All products have been returned.',
  'retcart.heading.methods': 'HOW DO YOU WANT TO RETURN YOUR PRODUCTS?',
  'retcart.method.toClientShop': 'TAKE THE PRODUCTS TO ONE OF OUR SHOPS NEAR YOU',
  'retcart.method.outvio': 'USE OUR COURIER TO SHIP YOUR RETURN BACK TO US',
  'retcart.method.ownCourier': 'SEND US YOUR RETURN WITH YOUR OWN COURIER',
  'retcart.method.exchange': 'EXCHANGE OR REPLACE PRODUCTS INSTEAD OF RETURNING THEM',
  'retcart.inst.toClientShop.heading':
    'CONFIRM YOUR RETURN AND TAKE THE PRODUCTS TO A SHOP NEAR YOU',
  'retcart.inst.toClientShop.text1':
    'TO FINALIZE YOUR RETURN PLEASE Click the CONFIRM button below:',
  'retcart.inst.toClientShop.text2':
    'A Return Sheet will be created for you and you will need to take the products to one of our shops before {dueDate}.',
  'retcart.inst.toClientShop.text3': 'You can find a complete list of our shops here: {link}.',
  'retcart.inst.toClientShop.text3.link': 'list of shop locations',
  'general.confirm': 'confirm',
  'retcart.inst.ownCourier.heading':
    'CONFIRM YOUR RETURN AND SHIP THE PRODUCTS WITH A COURIER OF YOUR CHOICE',
  'retcart.inst.ownCourier.text1': 'TO FINALIZE YOUR RETURN PLEASE Click the CONFIRM button below:',
  'retcart.inst.ownCourier.text2':
    'A Return Sheet will be created for you and you will need to make sure you send your products before {dueDate}.',
  'retcart.inst.ownCourier.text3': 'You will need to send the products to:',
  'retcart.inst.ownCourier.text4':
    'PS: You can use any courier you like and will have to pay for the return cost. We recommend using a trustworthy tracked service to make sure your return reaches us safely.',
  'retcart.inst.exchange.heading': 'LET US KNOW THE DETAILS AND CONFIRM YOUR EXCHANGE',
  'retcart.inst.exchange.text1':
    'With which product, size and quantity would you like to exchange the current product(s)?',
  'retcart.inst.exchange.text2': 'Make sure to include all the necessary details in your message.',
  'retcart.inst.exchange.text.placeholder': 'Leave us your exchange details here.',
  'retcart.inst.exchange.text3': `To finalize your exchange, please click CONFIRM below.

An email with your request for exchange will then be sent to us and we will get in touch with you to agree on the details of the exchange. A copy of the email will also be sent to your email address.`,
  'retcart.confirm-return-terms': 'I have reviewed my return and accept the {terms}',
  'retcart.confirm-return-terms.terms': 'Terms and Conditions',
  'retcart.confirm.heading': 'RETURN COMPLETED',
  'retcart.confirm.text3': 'TO MAKE SURE EVERYTHING GOES SMOOTH WITH YOUR RETURN:',
  'retcart.confirm.toClientShop.text4':
    '1. Print out the Return Sheet or save the electronic copy we have sent to your email.',
  'retcart.confirm.toClientShop.text5': '2. Take the products to {fillerShop} before {dueDate}. ',
  'retcart.confirm.toClientShop.text5.fillerShop': 'a shop near you',
  'retcart.confirm.toClientShop.text6': '3. Make sure to bring your Return Sheet with you.',
  'retcart.download-return-sheet': 'DOWNLOAD RETURN SHEET',
  'retcart.download-label': 'DOWNLOAD LABEL AND RETURN SHEET',
  'retcart.confirm.ownCourier.text1':
    '1. Print out the Return Sheet and put it inside the package together with the products you are returning.',
  'retcart.confirm.ownCourier.text2':
    '2. Ship your return with a trustworthy trackable courier of your choice to:',
  'retcart.confirm.ownCourier.text3': '3. Make sure to ship out your return before {dueDate}.',
  'retcart.confirm.outvio.text1':
    'YOUR RETURN HAS BEEN PROCESSED SUCCESSFULLY, PLEASE DOWNLOAD YOUR SHIPPING LABEL BY CLICKING THE BELOW BUTTON',
  'retcart.confirm.outvio.text2': '(the label has also been sent to your email):',
  'retcart.confirm.outvio.text3':
    'TO MAKE SURE EVERYTHING GOES SMOOTHLY WHEN THE COURIER COMES, PLEASE:',
  'retcart.confirm.outvio.text4': '1. Print out the Return Sheet and cut off the shipping label.',
  'retcart.confirm.outvio.text5':
    '2. Put the Return Sheet inside the package and stick the label onto your securely closed package.',
  'retcart.confirm.outvio.text6':
    '3. Sit back, relax and wait for the courier to pick up your return!',
  'retcart.inst.headline.important': 'IMPORTANT:',
  'retcart.inst.hasPickup.noLabel': `1. Review your pickup address and products in your return.{lineBreak}
      2. After clicking "CONFIRM", a Return Sheet will be created.{lineBreak}
      3. Place the return items inside the package and close the box. {fillerOptionally}, you can also add the Return Sheet inside the box.{lineBreak}
      4. Once you click Confirm, we will notify the courier and they will come to pickup your return in the next 2 business days. IMPORTANT! You do not need to print any shipping label. The courier will bring one when coming to pickup the return`,
  'retcart.inst.hasPickup.noLabel.fillerOptionally': 'Optionally',
  'retcart.inst.hasPickup.byEmail': `1. Review your pickup address and products in your return.{lineBreak}
      2. After clicking "CONFIRM", a Return Sheet will be created and a pickup request will be sent to our courier. {fillerNotice}.{lineBreak}
      3. Once you receive the Shipping Label, put the Return Sheet inside the package, close it and stick the shipping label onto the package.{lineBreak}
      4. Our courier will come to pickup your return in the next 2 business days.`,
  'retcart.inst.hasPickup.byEmail.fillerNotice':
    'You will receive the Shipping Label via email ({email}) within the next 2 business days.',
  'retcart.inst.hasPickup.provided': `1. Review your pickup address and products in your return.{lineBreak}
      2. After clicking "CONFIRM", a Return Sheet will be created. Please print it and cut off the shipping label for each package in your return.{lineBreak}
      3. Place the return items inside the package together with the Return Sheet, stick the shipping label and close the box.{lineBreak}
      4. The courier will come to pickup your return in the next 2 business days.`,
  'retcart.inst.noPickup.byEmail': `1. Review the products in your return{lineBreak}
      2. After clicking "CONFIRM", a Return Sheet will be created. {fillerNotice}{lineBreak}
      3. You will receive the return shipping label by email. Once you receive the Shipping Label, put the Return Sheet inside the package, close it and stick the shipping label onto the package.{lineBreak}
      4. You will need to take the package(s) to a courier pickup point near you within 5 business days. To find the pickup point closest to you please visit the couriers homepage {fillerCourierHomepage}.`,
  'retcart.inst.noPickup.byEmail.fillerNotice':
    ' You will receive the Shipping Label via email to ({email}) within the next 2 days.',
  'retcart.inst.noPickup.byEmail.fillerCourierHomepage': 'here',
  'retcart.inst.noPickup.provided': `1. Review the products in your return{lineBreak}
      2. After clicking "CONFIRM", a Return Sheet will be created. Please print it and cut off the shipping label for each package in your return.{lineBreak}
      3. Put the return items inside the package together with the Return Sheet, stick the shipping label on the package and close the box.{lineBreak}
      4. You will need to take the package(s) to a courier pickup point near you within 5 days. To find the pickup point closest to you please visit the couriers homepage {fillerCourierHomepage}.`,
  'retcart.inst.noPickup.provided.fillerCourierHomepage': 'here',
  'retcart.confirm.outvio.head-sheet':
    'YOUR RETURN HAS BEEN PROCESSED SUCCESSFULLY, PLEASE DOWNLOAD YOUR RETURN SHEET BY CLICKING THE BELOW BUTTON',
  'retcart.confirm.outvio.head-sheet.note': '(the Return Sheet has also been sent to your email):',
  'retcart.confirm.outvio.head-label':
    'YOUR RETURN HAS BEEN PROCESSED SUCCESSFULLY, PLEASE DOWNLOAD YOUR SHIPPING LABEL BY CLICKING THE BELOW BUTTON',
  'retcart.confirm.outvio.head-label.note': '(the label has also been sent to your email):',
  'retcart.confirm.outvio.head-label-by-email': 'YOUR RETURN HAS BEEN PROCESSED SUCCESSFULLY',
  'retcart.confirm.outvio.hasPickup.noLabel.head':
    'TO MAKE SURE EVERYTHING GOES SMOOTHLY WHEN THE COURIER COMES, PLEASE:',
  'retcart.confirm.outvio.hasPickup.noLabel': `1. Print out the Return Sheet (optional).{lineBreak}
      2. Include the Return Sheet in your securely closed package. NO SHIPPING LABEL NEEDED!{lineBreak}
      3. The courier will come to pick up your return within the next 2 business days and will bring a shipping label for your return.`,
  'retcart.confirm.outvio.hasPickup.byEmail.head':
    'PS: THE SHIPPING LABEL WILL BE SENT TO YOUR EMAIL WITHIN THE NEXT FEW DAYS.',
  'retcart.confirm.outvio.hasPickup.byEmail': `1. Once you receive the Shipping Label on your email, print it out together with the Return Sheet.{lineBreak}
      2. Place the Return Sheet inside the package, close it and stick the shipping label onto it.{lineBreak}
      3. The courier will come to pick up your return within the next 2 business days.`,
  'retcart.confirm.outvio.hasPickup.provided': `1. Print out the Return Sheet and cut off the shipping label.{lineBreak}
      2. Place the Return Sheet inside the package, close it and stick the shipping label onto it.{lineBreak}
      3. The courier will come to pick up your return within the next 2 business days.`,
  'retcart.confirm.outvio.noPickup.byEmail': `1. Once you receive the Shipping Label on your email, print it together with the Return Sheet.{lineBreak}
      2. Place the Return Sheet inside the package, close it and stick the shipping label onto it.{lineBreak}
      3. Take your package to a courier pickup point near you. To find the pickup point closest to you please visit the couriers homepage {filler}.`,
  'retcart.confirm.outvio.noPickup.byEmail.filler': 'here',
  'retcart.confirm.outvio.noPickup.provided': `1. Print out the Return Sheet and cut off the shipping label.{lineBreak}
      2. Place the Return Sheet inside the package, close it and stick the shipping label onto it.{lineBreak}
      3. Take your package to a courier pickup point near you. To find the pickup point closest to you please visit the couriers homepage {filler}.`,
  'retcart.confirm.outvio.noPickup.provided.filler': 'here',
  'general.retcart.restart.btn': 'Restart return process',
  'general.heading.refund.methods': 'HOW DO YOU WANT TO RECEIVE YOUR REFUND?',
  'retcart.method.SAME_AS_PAYMENT': 'REFUND TO MY ORIGINAL PAYMENT METHOD',
  'retcart.method.CREDIT_IN_STORE': 'STORE CREDIT FOR FUTURE PURCHASES',
  'retcart.confirm.exchange.heading': 'EXCHANGE REQUEST COMPLETED',
  'retcart.confirm.exchange.text1': 'YOUR EXCHANGE REQUEST HAS BEEN RECEIVED SUCCESSFULLY.',
  'retcart.confirm.exchange.text2':
    '(A copy of your exchange request has also been sent to your email address).',
  'retcart.confirm.exchange.text3':
    'We will get in touch with you shortly to let you know the details of your exchange.',
  'retcart.confirm.exchange.text4': 'THANK YOU!',
  'general.cancel': 'cancel',
  'general.retcart.init-restart':
    'If this issue persists, please restart the process by clicking HERE',
  'retcart.return-method.toClientShop': 'IN STORE',
  'retcart.return-method.outvio': 'OUR COURIER',
  'retcart.return-method.ownCourier': 'OWN COURIER',
  'retcart.return-method.exchange': 'EXCHANGE / REPLACE',
  'retcart.return-method.SAME_AS_PAYMENT': 'REFUND',
  'retcart.return-method.CREDIT_IN_STORE': 'STORE CREDIT',
  'retcart.return-method.pickup': 'PICKUP',
  'retcart.return-method.dropoff': 'DROP-OFF',
  'general.heading.pickup.methods':
    'DO YOU WANT TO DROP-OFF YOUR PACKAGE OR HAVE THE COURIER PICK IT UP?',
  'retcart.method.pickup': 'I WANT THE COURIER TO COME TO PICK UP THE RETURN FROM HOME',
  'retcart.method.dropoff': 'I WANT TO TAKE THE PACKAGE TO A COURIER PICKUP POINT MYSELF',
  'retcart.img-attach-desc': 'Upload pictures of packaging, product and shipping label',
  'retcart.confirm.outvio.noPickup.noLabel.head':
    'IMPORTANT! BEFORE TAKING THE RETURN TO A DROP-OFF POINT, PLEASE:',
  'retcart.title.pickup': 'pickup',
  'retcart.title.dropoff': 'dropoff',
  'general.tracking.manage-exception': 'Manage exception on courier website',
  'general.error.id-ver-token-missing':
    'Token is required for authentication. Please check the email sent for authentication.',
  'general.error.id-ver.token-missing':
    'Error in initialization. Please make sure you use an evergreen browser and that its updated.',
  'general.error.fileupload.file-too-large': 'Maximum file size {size} MB',
  'general.error.fileupload.too-many-files': 'Maximum {number} file(s)',
  'general.error.fileupload.file-invalid-type': 'Invalid file type',
  'general.error.fileupload.incorrect.size.max':
    'Image resolution must be at most {width} by {height}',
};
