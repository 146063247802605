import {
  type Dispatch,
  type InputHTMLAttributes,
  type ReactNode,
  type SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { type Path, useFormContext } from 'react-hook-form';
import { type FieldValues } from 'react-hook-form';

import { useHookFormMask } from 'use-mask-input';

import { get } from '../../../utils';
import { InputBackgroundFragment, InputTextVariant, RegularInputFragment } from './fragments';

export type TextInputProps = InputHTMLAttributes<HTMLInputElement> & {
  isComplete?: boolean;
  isLocked?: boolean;
  name: string;
  isFocused?: boolean;
  setIsFocused?: Dispatch<SetStateAction<boolean>>;
  isError?: boolean;
  className?: string;
  backgroundClassName?: string;
  inputClassName?: string;
  icon?: ReactNode;
  IconTag?: 'button' | 'div';
  iconPosition?: 'left' | 'right';
  onIconCtaClick?: (event: React.MouseEvent<HTMLElement>) => void;
  errorMessage?: ReactNode;
  register?: (name: string, mask?: string) => any;
  hasIconDivider?: boolean;
  variant?: InputTextVariant;
  mask?: string;
  maskPlaceholder?: string;
  helpTooltip?: string;
};

export function TextInput({
  isComplete = false,
  isLocked = false,
  className,
  inputClassName,
  backgroundClassName,
  iconPosition = 'left',
  icon,
  name,
  onIconCtaClick,
  type = 'text',
  isFocused = false,
  setIsFocused,
  isError = false,
  errorMessage,
  IconTag = 'div',
  register,
  disabled,
  hasIconDivider,
  variant,
  helpTooltip,
  mask,
  maskPlaceholder,
  ...otherProps
}: TextInputProps) {
  return (
    <InputBackgroundFragment
      isLocked={isLocked}
      isComplete={isComplete}
      className={className}
      backgroundClassName={backgroundClassName}
      icon={icon}
      IconTag={IconTag}
      iconPosition={iconPosition}
      onIconCtaClick={onIconCtaClick}
      isFocused={isFocused}
      isError={isError}
      errorMessage={errorMessage}
      disabled={disabled}
      hasIconDivider={hasIconDivider}
      variant={variant}
      helpTooltip={helpTooltip}
    >
      <RegularInputFragment
        isComplete={isComplete}
        isLocked={isLocked}
        onIconCtaClick={onIconCtaClick}
        inputClassName={inputClassName}
        iconPosition={iconPosition}
        icon={icon}
        name={name}
        type={type}
        setIsFocused={setIsFocused}
        isError={isError}
        variant={variant}
        register={register}
        disabled={disabled}
        hasIconDivider={hasIconDivider}
        mask={mask}
        maskPlaceholder={maskPlaceholder}
        {...otherProps}
      />
    </InputBackgroundFragment>
  );
}

export type _TextInputRhfProps<T> = {
  isLocked?: boolean;
  className?: string;
  inputClassName?: string;
  backgroundClassName?: string;
  name: Path<T>;
  icon?: ReactNode;
  iconPosition?: 'left' | 'right';
  onIconCtaClick?: (name: Path<T>) => void;
  hasIconDivider?: boolean;
  helpTooltip?: string;
  mask?: string;
  maskPlaceholder?: string;
};

export type TextInputRhfProps<T extends FieldValues> = InputHTMLAttributes<HTMLInputElement> &
  _TextInputRhfProps<T>;

export function TextInputRhf<T extends FieldValues>({
  onIconCtaClick,
  name,
  helpTooltip,
  mask,
  maskPlaceholder,
  ...otherProps
}: TextInputRhfProps<T>) {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const {
    register,
    unregister,
    getFieldState,
    formState: { errors },
  } = useFormContext<T>();

  const registerWithMask = useHookFormMask(register);

  const IconTag = useMemo(() => (onIconCtaClick ? 'button' : 'div'), [onIconCtaClick]);

  const handleIconCtaClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      onIconCtaClick?.(name);
    },
    [name, onIconCtaClick],
  );

  useEffect(() => {
    return () => {
      const { isDirty } = getFieldState(name as Path<T>);
      unregister(name as Path<T>, { keepDirty: true, keepDefaultValue: true, keepValue: isDirty });
    };
  }, [name, unregister, getFieldState]);

  const fieldError = useMemo(() => get(errors, name), [errors[name]?.message, name]);

  const registerToUse = mask ? registerWithMask : register;

  return (
    <TextInput
      name={name}
      register={registerToUse as any}
      mask={mask}
      maskPlaceholder={maskPlaceholder}
      IconTag={IconTag}
      onIconCtaClick={onIconCtaClick && handleIconCtaClick}
      isFocused={isFocused}
      setIsFocused={setIsFocused}
      isError={!!fieldError}
      errorMessage={fieldError?.message as string}
      helpTooltip={helpTooltip}
      {...otherProps}
    />
  );
}

export default TextInput;
