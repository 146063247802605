import React, { VFC, useMemo } from 'react';

import { NorthRounded } from '@mui/icons-material';

import { DisplayStep, OutvioStatusCodes, StepStatus } from '../../../../Interfaces';
import Dot from './Icons/Dot';

interface IconContainerProps {
  step: DisplayStep;
}

type LineType = StepStatus | 'none';
type IconType = 'dot' | 'none';

const GRAY_LINE_STATUS = [StepStatus.BACKTRACK, StepStatus.BACKTRACK_INCIDENT, StepStatus.PENDING];
const BACKTRACK_STATUS = [StepStatus.BACKTRACK, StepStatus.BACKTRACK_INCIDENT];

const getLine = ({
  lineStatus,
  stepStatus,
}: {
  lineStatus?: StepStatus;
  stepStatus: StepStatus;
}): LineType => {
  if (!lineStatus) return 'none';
  return !GRAY_LINE_STATUS.includes(stepStatus) && !GRAY_LINE_STATUS.includes(lineStatus)
    ? StepStatus.DONE
    : StepStatus.PENDING;
};

const useIconContainer = ({
  step: { status, prevStatus, nextStatus, isSubStep, parent },
}: IconContainerProps): { topLine: LineType; bottomLine: LineType; icon: IconType } => {
  if (isSubStep && parent)
    return {
      icon: 'none',
      bottomLine: parent.nextStatus || 'none',
      topLine: parent.nextStatus || 'none',
    };
  const topLine = getLine({ lineStatus: prevStatus, stepStatus: status });
  const bottomLine = getLine({ lineStatus: nextStatus, stepStatus: status });
  return { icon: 'dot', topLine, bottomLine };
};

const BacktrackArrow: VFC<{ step: DisplayStep; pos: 'top' | 'bot' }> = ({
  step: { status, isSubStep },
  pos,
}) => {
  if (BACKTRACK_STATUS.includes(status) && !isSubStep && pos === 'top') {
    return (
      <div className="absolute z-[10] left-0 right-0 mx-auto w-min bottom-[36px]">
        <NorthRounded
          className="text-tp-primary stroke-tp-secondaryBg stroke-[4px]"
          sx={{ paintOrder: 'stroke', fontSize: '16px' }}
        />
      </div>
    );
  }

  return null;
};

const Line: VFC<{ line: LineType; pos: 'bot' | 'top'; step: DisplayStep }> = ({
  line,
  pos,
  step,
}) => {
  // Return line null if the line is not needed
  if (line === 'none') return null;

  if (
    pos === 'top' &&
    step.code === OutvioStatusCodes.DELIVERED_TO_SHOP &&
    step.prevStatus === 'done'
  ) {
    return (
      <div className="z-[8] h-[18px] absolute left-0 right-0 mx-auto w-[2px] bg-tp-primary top-0" />
    );
  }

  if (
    pos === 'bot' &&
    step.code === OutvioStatusCodes.DELIVERED_TO_SHOP &&
    step.nextStatus === 'done'
  ) {
    return (
      <div className="z-[7] h-full absolute left-0 right-0 mx-auto w-[2px] bg-tp-primary bottom-0" />
    );
  }

  if (pos === 'bot' && step.code === OutvioStatusCodes.DELIVERED_TO_SHOP) {
    return (
      <div className="z-[7] h-full absolute left-0 right-0 mx-auto w-[1px] bg-tp-primaryAlt bottom-0" />
    );
  }

  return (
    <>
      <BacktrackArrow step={step} pos={pos} />
      <div
        className={`z-[8] h-1/2 absolute left-0 right-0 mx-auto ${
          GRAY_LINE_STATUS.includes(line) ? 'w-[1px] bg-tp-primaryAlt' : 'w-[2px] bg-tp-primary'
        } ${pos === 'bot' ? 'bottom-0' : 'top-0'}`}
      />
    </>
  );
};

const Icon: VFC<{ step: DisplayStep; icon: IconType }> = ({ step, icon }) => {
  switch (icon) {
    case 'dot':
      return <Dot step={step} />;
    case 'none':
    default:
      return null;
  }
};

const IconContainer: VFC<IconContainerProps> = ({ step }) => {
  const { topLine, bottomLine, icon } = useIconContainer({ step });
  const isLastDoneStepClassNames = useMemo<string>(() => {
    if (!step.isLastDoneStep) return '';
    if (step.code === OutvioStatusCodes.DELIVERED_TO_SHOP) {
      return 'm-auto top-[13px] bottom-auto right-0 left-0 w-[20px] h-[20px] absolute z-[7] rounded-full bg-tp-primaryAlt';
    }
    return 'm-auto top-0 bottom-0 right-0 left-0 w-[20px] h-[20px] absolute z-[7] rounded-full bg-tp-primaryAlt';
  }, [step.code, step.isLastDoneStep]);

  return (
    <div className="relative">
      {step.isLastDoneStep && <div className={isLastDoneStepClassNames} />}
      <Line line={topLine} pos="top" step={step} />
      <Icon icon={icon} step={step} />
      <Line line={bottomLine} pos="bot" step={step} />
    </div>
  );
};

export default IconContainer;
