import React, { FC, useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Field } from 'formik';

import EditModalContext from '../../../Context/EditModalContext';
import { BannerModes, BannerTypes } from '../../../Interfaces';
import { EDIT_MODE_TABS } from '../../../Interfaces/IEditModal';
import { RadioInputOption } from '../../Common/Inputs/RadioInput';
import Toggle from '../../Common/Inputs/Toggle';
import BannerUploader from '../BannerUploader';
import LanguageSelector from '../LanguageSelector';
import Base from './Base';

export const BANNER_RADIO_TYPES: RadioInputOption[] = [
  {
    value: BannerModes.VERTICAL,
    label: 'Vertical banner',
  },
  {
    value: BannerModes.HORIZONTAL,
    label: 'Horizontal banner',
  },
];

const Banner: FC = () => {
  const {
    formData: { isBannerDisplay, regionalSettings },
    setFieldValue,
  } = useContext(EditModalContext);
  const { t } = useTranslation('trackingPage', { keyPrefix: 'editMode.modules.banner' });
  const languages = regionalSettings.map((setting) => setting.language);
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0]);
  const [selectedBannerType, setSelectedBannerType] = useState(
    regionalSettings[0].banners?.[BannerTypes.DESKTOP]?.mode || BannerModes.HORIZONTAL,
  );
  useEffect(() => {
    for (let i = 0; i < regionalSettings.length; i += 1) {
      if (regionalSettings[i].banners?.[BannerTypes.DESKTOP]) {
        setFieldValue(
          `regionalSettings[${i}].banners.${BannerTypes.DESKTOP}.mode`,
          selectedBannerType,
        );
      }
    }
  }, [selectedBannerType]);

  return (
    <Base id={EDIT_MODE_TABS.BANNER} title={t('title')}>
      <div className="flex">
        <Field component={Toggle} name="isBannerDisplay" type="checkbox" />
        <span className="text-cyan-800 text-sm font-semibold ml-6">{t('uploadToOrdersPage')}</span>
      </div>
      <div className={`${isBannerDisplay ? '' : 'hidden'} ml-[60px]`}>
        <span className="text-sm font-light mb-6 text-slate-900 text-opacity-80 mb-6 block">
          {t('whereToDisplayBanner')}
        </span>
        <div className="flex flex-col space-y-3 mb-10">
          {BANNER_RADIO_TYPES.map(({ value, label }) => (
            <label className="flex items-center" key={value} htmlFor={label}>
              <input
                type="radio"
                onChange={() => setSelectedBannerType(value)}
                checked={value === selectedBannerType}
                className="appearance-none relative rounded-full w-5 h-5 bg-slate-300 before:bg-white checked:bg-primary before:border-white before:border before:border-[3px] before:block before:absolute before:rounded-full before:top-0.5 before:left-0.5 before:w-4 before:h-4 checked:before:bg-primary"
                id={label}
              />
              <span className="text-slate-700 text-sm ml-3">{label}</span>
            </label>
          ))}
        </div>
        <div className="mb-4">
          <LanguageSelector
            languages={languages}
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
          />
        </div>
        <BannerUploader selectedLanguage={selectedLanguage} selectedMode={selectedBannerType} />
      </div>
    </Base>
  );
};

export default Banner;
