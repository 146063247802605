import React, { useContext, useMemo, useRef, useState } from 'react';

import {
  ClickAwayListener,
  Paper,
  Popper,
  Theme,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckCircleRounded';
import LangIcon from '@material-ui/icons/Language';
import cn from 'classnames';

import { CustomerSupportedLanguage2Letter, LanguageNames } from '../types/CustomerGeneral';
import OVCLangSwitcherContext from './OVCLangSwitcherContext';
import OVCStoreDataContext from './OVCStoreDataContext';
import { LANG_TO_LOCALE } from './constants';

interface StyleProps {
  headerAction: string;
  portalHeader: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  positioner: {
    position: 'absolute',
    top: '40px',
    right: '30px',

    [theme.breakpoints.down('xs')]: {
      right: '10px',
      top: '30px',
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    fontSize: '16px',
    lineHeight: '16px',
    color: (props) => props.headerAction,

    userSelect: 'none',
    cursor: 'pointer',

    fontFamily: '"Cabin", "Open Sans", sans-serif',
  },
  iconHolder: {
    marginRight: '5px',
    fontSize: '22px',
  },
  paperRoot: {
    padding: '15px 30px',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    backgroundColor: (props) => props.headerAction,

    '& > p': {
      fontFamily: '"Cabin", "Open Sans", sans-serif',
      color: (props: any) => props.portalHeader,
      fontSize: '16px',
      lineHeight: '25px',
      margin: 0,

      userSelect: 'none',
      cursor: 'pointer',

      '& > svg': {
        verticalAlign: 'middle',
        marginRight: '5px',
        transform: 'translateY(-1px)',
      },
    },
  },
  activeItem: {
    fontWeight: 700,
  },
}));

const FALLBACK_LANGUAGES: { name: CustomerSupportedLanguage2Letter }[] = [
  { name: 'en' },
  { name: 'es' },
  { name: 'et' },
];

const OVCLangSwitcher = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { storeData } = useContext(OVCStoreDataContext);
  const langContext = useContext(OVCLangSwitcherContext);
  const isV2: boolean = storeData?.featureFlags?.newTrackingPage && storeData.portalV2;
  const classes = useStyles({
    portalHeader: isV2
      ? storeData.portalV2.colors.primaryBg
      : storeData.portalSettings.colors.primaryBg,
    headerAction: isV2
      ? storeData.portalV2.colors.primaryText
      : storeData.portalSettings.colors.primaryText,
  });

  const contRef = useRef<HTMLDivElement>(null);
  const [isPopupOpen, setPopup] = useState(false);

  const langOptions = useMemo(
    () =>
      (storeData.languages.length > 0 ? storeData.languages : FALLBACK_LANGUAGES).map(
        (langOpt) => ({
          shortLocale: langOpt.name,
          langCode: LANG_TO_LOCALE[langOpt.name],
        }),
      ),
    [storeData],
  );

  return (
    <div className={classes.positioner}>
      <div
        className={classes.container}
        ref={contRef}
        onClick={() => setPopup(true)}
        data-cy="ovc-lang-switcher-container"
      >
        <div className={classes.iconHolder}>
          <LangIcon fontSize="inherit" color="inherit" />
        </div>
        {!isMobile && <span>{LanguageNames[langContext.appLang]}</span>}
      </div>
      <Popper
        open={isPopupOpen}
        anchorEl={contRef.current}
        placement="bottom"
        modifiers={{
          offset: {
            enabled: true,
            offset: '0px, 10px',
          },
        }}
      >
        <ClickAwayListener onClickAway={() => setPopup(false)}>
          <Paper classes={{ root: classes.paperRoot }} data-cy="ovc-lang-switcher-popup">
            {langOptions.map((langOpt) => (
              <p
                key={`${langOpt.langCode}-${
                  langContext.appLang === langOpt.langCode ? 'active' : 'inactive'
                }`}
                className={cn({ [classes.activeItem]: langContext.appLang === langOpt.langCode })}
                onClick={() => {
                  setPopup(false);
                  langContext.changeLangTo(langOpt.langCode);
                }}
                data-cy={`ovc-lang-switcher-opt-${langOpt.shortLocale}`}
              >
                {langContext.appLang === langOpt.langCode && (
                  <CheckIcon fontSize="inherit" color="inherit" />
                )}
                <span>{LanguageNames[langOpt.langCode]}</span>
              </p>
            ))}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

export default OVCLangSwitcher;
