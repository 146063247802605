import { ReactNode, memo } from 'react';

import { twMerge } from 'tailwind-merge';

const Price = memo(({ currency, value, discountPrice, classNameDiscount }: PriceProps) => {
  const withDiscount =
    discountPrice !== undefined && typeof discountPrice === 'number' && discountPrice > 0;
  const isValueNumber = !Array.isArray(value) && typeof value === 'number';
  const formattedValue = isValueNumber ? value?.toFixed(2) : value;

  let symbol: ReactNode = '';
  switch (currency) {
    case 'GBP':
    case '£':
      symbol = '£';
      return (
        <span>
          {addNormalPrice({ symbol, price: formattedValue, withDiscount, classNameDiscount })}
          {addDiscountPrice(symbol, discountPrice)}
        </span>
      );
    case 'USD':
    case '$':
      symbol = '$';
      return (
        <span>
          {addNormalPrice({ symbol, price: formattedValue, withDiscount, classNameDiscount })}
          {addDiscountPrice(symbol, discountPrice)}
        </span>
      );
    case 'PLN':
    case 'zł':
      symbol = <span className="lowercase">zł</span>;
      return (
        <span>
          {addNormalPrice({
            symbol,
            price: formattedValue,
            withDiscount,
            reverse: true,
            classNameDiscount,
          })}
          {addDiscountPrice(symbol, discountPrice)}
        </span>
      );
    case 'EUR':
    case '€':
    case undefined:
      symbol = `\u20ac`;
      return (
        <span>
          {addNormalPrice({ symbol, price: formattedValue, withDiscount, classNameDiscount })}
          {addDiscountPrice(symbol, discountPrice)}
        </span>
      );
    default:
      symbol = `${currency} `;
      return (
        <span>
          {addNormalPrice({ symbol, price: formattedValue, withDiscount, classNameDiscount })}
          {addDiscountPrice(symbol, discountPrice)}
        </span>
      );
  }
});

export { Price };

interface PriceProps {
  currency?: string;
  value: ReactNode;
  discountPrice?: number | null;
  classNameDiscount?: string;
}

const addDiscountPrice = (symbol: ReactNode, discountPrice?: number | null, reverse = false) => {
  if (discountPrice !== undefined && typeof discountPrice === 'number' && discountPrice > 0) {
    return (
      <>
        {!reverse && symbol}
        {discountPrice?.toFixed(2)}
        {reverse && symbol}
      </>
    );
  }
  return '';
};

const addNormalPrice = ({
  symbol,
  price,
  withDiscount = false,
  reverse = false,
  classNameDiscount,
}: {
  symbol: ReactNode;
  price?: any;
  withDiscount: boolean;
  reverse?: boolean;
  classNameDiscount?: string;
}) => {
  if (price) {
    return (
      <>
        <span className={withDiscount ? twMerge('line-through', classNameDiscount) : ''}>
          {!reverse && symbol}
          {price}
          {reverse && symbol}
        </span>
        {withDiscount && <span>&nbsp;</span>}
      </>
    );
  }
  return '';
};
