import { Descendant } from 'slate';
import * as Yup from 'yup';

import {
  BannerModes,
  BannerTypes,
  CancelOrderType,
  IBanner,
  IBanners,
  ICancelOrder,
  ICancelledStep,
  IDeliveryInfo,
  IInfoStep,
  ILanguage,
  IOrderInfo,
  IOrderStatusInfo,
  IOrderStatusSteps,
  IPortalSettings,
  IQuestion,
  IQuestions,
  IRating,
  IRegionalSettings,
  IReturnMethod,
  IReturnMethods,
  IReturnStep,
  OutvioStatusCodes,
  ReturnMethods,
} from '../Interfaces';

export const orderStatusInfoSchema: Yup.SchemaOf<IOrderStatusInfo> = Yup.object().shape({
  title: Yup.string().required(),
  manageIncidentText: Yup.string().required(),
  manageIncidentWithCourierText: Yup.string().required(),
  moreInfoText: Yup.string().required(),
});

export const cancelledStepSchema: Yup.SchemaOf<ICancelledStep> = Yup.object().shape({
  title: Yup.string().required(),
  comment: Yup.string().required(),
  button: Yup.string().required(),
});

export const descendantArraySchema: Yup.SchemaOf<Descendant[]> = Yup.array().of(
  Yup.object().shape({}).required() as Yup.SchemaOf<Descendant>,
);

export const infoStepSchema: Yup.SchemaOf<IInfoStep> = Yup.object().shape({
  title: Yup.string().required(),
  message: Yup.string().required(),
  cancelDialogButton: Yup.string().notRequired(),
  confirmDialogButton: Yup.string().notRequired(),
  cancelOrderButton: Yup.string().notRequired(),
  checkbox: descendantArraySchema.required(),
});

export const returnMethodSchema: Yup.SchemaOf<IReturnMethod> = Yup.object().shape({
  text: Yup.string().required(),
});

export const returnMethodsSchema: Yup.SchemaOf<IReturnMethods> = Yup.object().shape({
  [ReturnMethods.STORE_CREDIT]: returnMethodSchema.required(),
  [ReturnMethods.ORIGINAL]: returnMethodSchema.required(),
});

export const returnStepSchema: Yup.SchemaOf<IReturnStep> = Yup.object().shape({
  title: Yup.string().required(),
  refundText: Yup.string().required(),
  button: Yup.string().required(),
  checkbox: descendantArraySchema.required(),
  methods: returnMethodsSchema.required(),
});

export const cancelOrderSchema: Yup.SchemaOf<ICancelOrder> = Yup.object().shape({
  cancelledStep: cancelledStepSchema.required(),
  infoStep: infoStepSchema.required(),
  returnStep: returnStepSchema.required(),
});

export const bannerSchema: Yup.SchemaOf<IBanner> = Yup.object().shape({
  imgSrc: Yup.string().required(),
  alt: Yup.string().required(),
  mode: Yup.mixed<BannerModes>().required(),
});

export const deliveryInfoSchema: Yup.SchemaOf<IDeliveryInfo> = Yup.object().shape({
  title: Yup.string().required(),
  returnTitle: Yup.string().required(),
  changeInformationText: Yup.string().required(),
  thanksTitle: Yup.string().required(),
  thanksText: Yup.string().required(),
  thanksButton: Yup.string().required(),
  confirmChangeButton: Yup.string().required(),
  checkboxText: descendantArraySchema.required(),
});

export const orderInfoSchema: Yup.SchemaOf<IOrderInfo> = Yup.object().shape({
  title: Yup.string().required(),
  cancelOrderText: Yup.string().required(),
  orderTotalText: Yup.string().required(),
  vatText: Yup.string().required(),
  shippingPriceText: Yup.string().required(),
});

export const questionSchema: Yup.SchemaOf<IQuestion> = Yup.object().shape({
  title: Yup.string().required(),
  order: Yup.number().required(),
  answer: descendantArraySchema.required(),
});

export const questionsSchema: Yup.SchemaOf<IQuestions> = Yup.object().shape({
  title: Yup.string().required(),
  questions: Yup.array().of(questionSchema).notRequired(),
});

export const orderStatusStepsSchema: Yup.SchemaOf<IOrderStatusSteps> = Yup.object().shape({
  [OutvioStatusCodes.RECEIVED]: Yup.string().required(),
  [OutvioStatusCodes.PROCESSING]: Yup.string().required(),
  [OutvioStatusCodes.CREATED]: Yup.string().required(),
  [OutvioStatusCodes.PICKED_UP]: Yup.string().required(),
  [OutvioStatusCodes.IN_TRANSIT]: Yup.string().required(),
  [OutvioStatusCodes.OUT_FOR_DELIVERY]: Yup.string().required(),
  [OutvioStatusCodes.DELIVERED_TO_PICKUP_POINT]: Yup.string().required(),
  [OutvioStatusCodes.DELIVERED_TO_SHOP]: Yup.string().required(),
  [OutvioStatusCodes.DELIVERED]: Yup.string().required(),
  [OutvioStatusCodes.HAND_OVER]: Yup.string().required(),
  [OutvioStatusCodes.DELETED_SHIPMENT]: Yup.string().required(),
});

export const ratingSchema: Yup.SchemaOf<IRating> = Yup.object().shape({
  body: Yup.string().required(),
  rateButton: Yup.string().required(),
  askLater: Yup.string().required(),
  thanks: Yup.string().required(),
});

export const bannersSchema: Yup.SchemaOf<IBanners> = Yup.object().shape({
  [BannerTypes.MOBILE]: bannerSchema.notRequired().default(undefined),
  [BannerTypes.DESKTOP]: bannerSchema.notRequired().default(undefined),
  url: Yup.string().notRequired(),
});

export const languageSchema: Yup.SchemaOf<ILanguage> = Yup.object().shape({
  ISOCode: Yup.string().required(),
  name: Yup.string().required(),
  isDefault: Yup.boolean().default(false),
});

export const regionalSettingsSchema: Yup.SchemaOf<IRegionalSettings> = Yup.object().shape({
  language: languageSchema.required(),
  banners: bannersSchema.required(),
  orderStatusInfo: orderStatusInfoSchema.required(),
  cancelOrder: cancelOrderSchema.required(),
  deliveryInfo: deliveryInfoSchema.required(),
  orderInfo: orderInfoSchema.required(),
  questions: questionsSchema.required(),
  shipmentNotFoundText: Yup.string().required(),
  orderStatusSteps: orderStatusStepsSchema.required(),
  rating: ratingSchema.required(),
});

export const editModalSchema: Yup.SchemaOf<IPortalSettings> = Yup.object().shape({
  allowOrderCancel: Yup.mixed<CancelOrderType>().notRequired(),
  autoRefundOnCancel: Yup.boolean().notRequired(),
  colors: Yup.object()
    .shape({
      primary: Yup.string().required(),
      primaryAlt: Yup.string().required(),
      secondary: Yup.string().required(),
      border: Yup.string().required(),
      inputBg: Yup.string().required(),
      alertColor: Yup.string().required(),
      alertAlt: Yup.string().required(),
      primaryText: Yup.string().required(),
      btnText: Yup.string().required(),
      primaryBg: Yup.string().required(),
      secondaryBg: Yup.string().required(),
    })
    .required(),
  customUrls: Yup.object()
    .shape({ tracking: Yup.string().notRequired(), returns: Yup.string().notRequired() })
    .required(),
  favicon: Yup.string().notRequired().nullable(true),
  isBannerDisplay: Yup.boolean().required(),
  isDeliveryInfoChangeable: Yup.boolean().required(),
  isOrderCancellable: Yup.boolean().notRequired(),
  useCustomMapMarker: Yup.boolean().required(),
  enableMapOnTrackingPage: Yup.boolean().required(),
  mapFeatureExpireAtTheEndOfMonth: Yup.boolean().required(),
  useCustomMapColors: Yup.boolean().required(),
  useRateOrder: Yup.boolean().required(),
  logo: Yup.string().notRequired().nullable(),
  customMapMarker: Yup.string().notRequired(),
  regionalSettings: Yup.array().of(regionalSettingsSchema),
});
