import { type HTMLProps } from 'react';

import { twMerge } from 'tailwind-merge';

export type HeadingType =
  | 'heading1'
  | 'heading2'
  | 'heading3'
  | 'heading4'
  | 'heading5'
  | 'heading6'
  | 'heading7';
export interface _HeadingProps {
  className?: string;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  type?: HeadingType;
  weight?: 'light' | 'regular' | 'medium' | 'semibold';
  noColor?: boolean;
}

export type HeadingProps = HTMLProps<HTMLHeadingElement> & _HeadingProps;

const headings = {
  heading1: 'text-[22px] leading-[28px] tracking-[-0.033em]',
  heading2: 'text-[18px] leading-[24px] tracking-[-0.324px]', // tracking Xd -18
  heading3: 'text-[14px] leading-[22px] tracking-[-0.018em]',
  heading4: 'text-[14px] leading-[22px] tracking-[-0.014em]',
  heading5: 'text-[12px] leading-[22px] tracking-[-0.012em]',
  heading6: 'text-[16px] leading-[24px] tracking-[-0.288px]', // tracking Xd -18
  heading7: 'text-[30px] leading-[28px] tracking-[-0.033em]',
};

const fontWeights = {
  light: 'font-light',
  regular: 'font-normal',
  medium: 'font-medium',
  semibold: 'font-semibold',
};

export function Heading({
  className,
  as: Tag = 'h4',
  type = 'heading2',
  weight = 'semibold',
  noColor = false,
  children,
}: HeadingProps) {
  return (
    <Tag
      data-testid="outvioui--heading"
      className={twMerge(
        'font-inter ',
        !noColor && 'text-v2blueGray-800',
        headings[type],
        fontWeights[weight],
        className,
      )}
    >
      {children}
    </Tag>
  );
}

export default Heading;
