import React, { Fragment, forwardRef, useContext } from 'react';

import get from 'lodash-es/get';

import { Theme, makeStyles } from '@material-ui/core';
import { useIntl } from 'localization';

import { CustomerSupportedLanguage2Letter } from '../types/CustomerGeneral';
import { QAEntry } from '../types/CustomerStoreData';
import OVCStoreDataContext from './OVCStoreDataContext';

interface StyleProps {
  bgColor: string;
  textColor: string;
}

const useStyles = makeStyles<Theme, StyleProps>({
  qnaContainer: {
    width: '100%',
    padding: '100px 136px',

    display: 'flex',
    flexDirection: 'column',

    backgroundColor: (props) => props.bgColor,
    color: (props) => props.textColor,

    '& h2': {
      marginBottom: '38px',
      marginTop: 0,
      fontWeight: 600,
      fontSize: '30px',
      lineHeight: '30px',
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    '@media (max-width: 800px)': { padding: '24px', '& h2': { fontSize: '24px' } },
    '@media (max-width: 1400px)': {
      padding: '100px 60px',
    },
  },
  columnContainer: {
    margin: '0 auto',
    display: 'grid',
    gridTemplateColumns: '400px 400px',
    gridColumnGap: '100px',
    gridTemplateAreas: '"left right"',
    '@media (max-width: 1400px)': {
      gridColumnGap: '50px',
      gridTemplateColumns: '1fr 1fr',
    },

    '@media (max-width: 1000px)': {
      gridTemplateColumns: '1fr',
      gridTemplateAreas: '"left" "right"',
    },
  },
  column: {
    width: '100%',
    '& h3': {
      marginBottom: '28px',
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: 600,
    },
    '& p': { fontSize: '16px', lineHeight: '22px', marginBottom: '30px' },
    '@media (max-width: 800px)': {
      '& h3': { fontSize: '18px', lineHeight: '18px' },
      '& p': { fontSize: '16px', lineHeight: '16px' },
    },
  },
});

interface IOVCLandingQnA {
  qnaType: 'returns' | 'tracking';
}

const OVCLandingQnA = forwardRef<HTMLDivElement, IOVCLandingQnA>(({ qnaType }, ref) => {
  const intl = useIntl();
  const { storeData } = useContext(OVCStoreDataContext);
  const classes = useStyles({
    bgColor: storeData.portalSettings.colors.secondaryBg,
    textColor: storeData.portalSettings.colors.primaryText,
  });

  const urlLang = intl.locale.substring(0, 2) as CustomerSupportedLanguage2Letter;

  const lang =
    typeof get(storeData, `portalSettings.${qnaType}.title.${urlLang}`) !== 'undefined'
      ? urlLang
      : 'en';

  return (
    <div className={classes.qnaContainer} ref={ref} data-cy="ovc-qna-container">
      <h2 data-cy="ovc-qna-title">
        {get(storeData, `portalSettings.${qnaType}.title.${lang}`, '')}
      </h2>
      <div className={classes.columnContainer}>
        <div className={classes.column} style={{ gridArea: 'left' }}>
          {(get(storeData, `portalSettings.${qnaType}.left`) || []).map(
            (item: QAEntry, index: number) => (
              <Fragment key={`${lang}-${index}`}>
                <h3>{item.question[lang]}</h3>
                <p>{item.answer[lang]}</p>
              </Fragment>
            ),
          )}
        </div>
        <div className={classes.column} style={{ gridArea: 'right' }}>
          {(get(storeData, `portalSettings.${qnaType}.right`) || []).map(
            (item: QAEntry, index: number) => (
              <Fragment key={`${lang}-${index}`}>
                <h3>{item.question[lang]}</h3>
                <p>{item.answer[lang]}</p>
              </Fragment>
            ),
          )}
        </div>
      </div>
    </div>
  );
});

export default OVCLandingQnA;
