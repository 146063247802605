import { Descendant } from 'slate';

export enum ReturnMethods {
  ORIGINAL = 'original',
  STORE_CREDIT = 'storeCredit',
}

export enum BannerModes {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
  MOBILE = 'mobile',
}

export enum ButtonVariant {
  PRIMARY = 'primary',
  TRANSPARENT = 'transparent',
}

export enum BannerTypes {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
}

export enum OutvioStatusCodes {
  DELETED_SHIPMENT = 'DELETED_SHIPMENT',
  BAD_ADDRESS = 'BA',
  CANCELLED = 'CAN',
  CREATED = 'CREA',
  DAMAGED = 'D',
  DELIVERED = 'DEL',
  DELIVERED_TO_PICKUP_POINT = 'DELPU',
  DELIVERED_TO_SHOP = 'DELSHOP',
  DESTROYED = 'DES',
  DELAYED = 'DLY',
  MISSING_DOCUMENTATION = 'DOC',
  HAND_OVER = 'HO',
  IMPORTANT_INCIDENTS = 'IMP',
  IN_TRANSIT = 'IT',
  LOST = 'L',
  NOT_AT_HOME = 'NAH',
  OUT_FOR_DELIVERY = 'OFD',
  OTHER_INCIDENTS = 'OTH',
  PARTIALLY_DELIVERED = 'PD',
  PICKED_UP = 'PU',
  REFUSED = 'REF',
  RETURN_TO_ORIGIN = 'RTO',
  SENT_TO_AGENCY = 'STA',
  STOLEN = 'T',
  RECEIVED = 'REC',
  PROCESSING = 'PRC',
}

export type DisplayStatusCodes =
  | OutvioStatusCodes.RECEIVED
  | OutvioStatusCodes.PROCESSING
  | OutvioStatusCodes.CREATED
  | OutvioStatusCodes.PICKED_UP
  | OutvioStatusCodes.IN_TRANSIT
  | OutvioStatusCodes.DELIVERED_TO_PICKUP_POINT
  | OutvioStatusCodes.DELIVERED_TO_SHOP
  | OutvioStatusCodes.OUT_FOR_DELIVERY
  | OutvioStatusCodes.DELIVERED
  | OutvioStatusCodes.HAND_OVER;

/**
 * Uncivilicised way of dealing with run-time type checking
 */
export const displayStatus: DisplayStatusCodes[] = [
  OutvioStatusCodes.RECEIVED,
  OutvioStatusCodes.PROCESSING,
  OutvioStatusCodes.CREATED,
  OutvioStatusCodes.PICKED_UP,
  OutvioStatusCodes.IN_TRANSIT,
  OutvioStatusCodes.OUT_FOR_DELIVERY,
  OutvioStatusCodes.DELIVERED_TO_PICKUP_POINT,
  OutvioStatusCodes.DELIVERED_TO_SHOP,
  OutvioStatusCodes.DELIVERED,
  OutvioStatusCodes.HAND_OVER,
];

export type IncidentStatusSteps =
  | OutvioStatusCodes.BAD_ADDRESS // IT, OFD
  | OutvioStatusCodes.CANCELLED // any
  | OutvioStatusCodes.DAMAGED // IT OFD
  | OutvioStatusCodes.DESTROYED // IT OFD
  | OutvioStatusCodes.DELAYED // IT
  | OutvioStatusCodes.MISSING_DOCUMENTATION // IT OFD
  | OutvioStatusCodes.IMPORTANT_INCIDENTS // any
  | OutvioStatusCodes.LOST // IT OFD
  | OutvioStatusCodes.NOT_AT_HOME // OFD
  | OutvioStatusCodes.OTHER_INCIDENTS // all
  | OutvioStatusCodes.PARTIALLY_DELIVERED // OFD
  | OutvioStatusCodes.REFUSED // OFD
  | OutvioStatusCodes.RETURN_TO_ORIGIN // OFD
  | OutvioStatusCodes.STOLEN; // IT OFD

export const incidentStatusSteps = [
  OutvioStatusCodes.BAD_ADDRESS,
  OutvioStatusCodes.CANCELLED,
  OutvioStatusCodes.DAMAGED,
  OutvioStatusCodes.DESTROYED,
  OutvioStatusCodes.DELAYED,
  OutvioStatusCodes.MISSING_DOCUMENTATION,
  OutvioStatusCodes.IMPORTANT_INCIDENTS,
  OutvioStatusCodes.LOST,
  OutvioStatusCodes.NOT_AT_HOME,
  OutvioStatusCodes.OTHER_INCIDENTS,
  OutvioStatusCodes.PARTIALLY_DELIVERED,
  OutvioStatusCodes.REFUSED,
  OutvioStatusCodes.RETURN_TO_ORIGIN,
  OutvioStatusCodes.STOLEN,
];

export interface IBanner {
  imgSrc: string;
  alt: string;
  mode: BannerModes;
}

export interface IMapColors {
  mapBase: string; // Same
  mapWater: string; // New | Serialised
  mapPath: string; // Same
  mapDivisor: string; // New | Serialised
  mapText: string; // Same
  mapPin: string; // Same
}

export interface IColors extends IMapColors {
  primary: string; // New | previously btnBg
  primaryAlt: string; // New | Serialised
  secondary: string; // New
  border: string; // New | Serialised
  inputBg: string; // New (same as border) | Serialised
  alertColor: string; // Same
  alertAlt: string; // New
  primaryText: string; // Same
  btnText: string; // Same
  primaryBg: string; // Same
  secondaryBg: string; // Same
}

export interface IBanners {
  [BannerTypes.MOBILE]?: IBanner;
  [BannerTypes.DESKTOP]?: IBanner;
  url?: string;
}

export interface IOrderStatusInfo {
  title: string;
  moreInfoText: string;
  manageIncidentText: string;
  manageIncidentWithCourierText: string;
}

export interface IDeliveryInfo {
  title: string;
  returnTitle: string;
  changeInformationText: string;
  thanksTitle: string;
  thanksText: string;
  thanksButton: string;
  confirmChangeButton: string;
  checkboxText: Descendant[];
}

export interface IOrderInfo {
  title: string;
  cancelOrderText: string;
  shippingPriceText: string;
  vatText: string;
  orderTotalText: string;
}

export interface IQuestions {
  title: string;
  questions?: IQuestion[];
}

export interface IQuestion {
  order: number;
  title: string;
  answer: Descendant[];
}

export interface IInfoStep {
  title: string;
  message: string;
  checkbox: Descendant[];
  confirmDialogButton?: string;
  cancelDialogButton?: string;
  cancelOrderButton?: string;
}

export interface IReturnMethod {
  text: string;
}

export interface IReturnMethods {
  [ReturnMethods.STORE_CREDIT]: IReturnMethod;
  [ReturnMethods.ORIGINAL]: IReturnMethod;
}

export interface IReturnStep {
  title: string;
  refundText: string;
  methods: IReturnMethods;
  button: string;
  checkbox: Descendant[];
}

export interface ICancelledStep {
  title: string;
  comment: string;
  button: string;
}

export interface ICancelOrder {
  infoStep: IInfoStep;
  returnStep: IReturnStep;
  cancelledStep: ICancelledStep;
}

export interface IOrderStatusSteps {
  [OutvioStatusCodes.RECEIVED]: string;
  [OutvioStatusCodes.PROCESSING]: string;
  [OutvioStatusCodes.CREATED]: string;
  [OutvioStatusCodes.PICKED_UP]: string;
  [OutvioStatusCodes.IN_TRANSIT]: string;
  [OutvioStatusCodes.OUT_FOR_DELIVERY]: string;
  [OutvioStatusCodes.DELIVERED_TO_PICKUP_POINT]: string;
  [OutvioStatusCodes.DELIVERED_TO_SHOP]: string;
  [OutvioStatusCodes.DELIVERED]: string;
  [OutvioStatusCodes.HAND_OVER]: string;
  [OutvioStatusCodes.DELETED_SHIPMENT]: string;
}

export interface IRating {
  body: string;
  askLater: string;
  rateButton: string;
  thanks: string;
}

export interface IRegionalSettings {
  language: ILanguage;
  banners?: IBanners;
  orderStatusInfo: IOrderStatusInfo;
  cancelOrder: ICancelOrder;
  deliveryInfo: IDeliveryInfo;
  orderInfo: IOrderInfo;
  questions: IQuestions;
  shipmentNotFoundText: string;
  orderStatusSteps: IOrderStatusSteps;
  rating: IRating;
}

export interface ICustomUrls {
  tracking?: string;
  returns?: string;
}

export interface ILanguage {
  isDefault: boolean;
  /**
   * Example et-EE for Estonian
   */
  ISOCode: string;
  /**
   * Example et for Estonian
   */
  name: string;
}

export interface IGeneral {
  colors: IColors;
  customUrls: ICustomUrls;
  favicon?: string | null;
  isBannerDisplay: boolean;
  isDeliveryInfoChangeable: boolean;
  isOrderCancellable?: boolean;
  logo?: string | null;
  useCustomMapMarker: boolean;
  useCustomMapColors: boolean;
  customMapMarker?: string;
  useRateOrder: boolean;
  enableMapOnTrackingPage?: boolean;
  mapFeatureExpireAtTheEndOfMonth?: boolean;
  allowOrderCancel?: CancelOrderType;
  autoRefundOnCancel?: boolean;
}

export interface IPortalSettings extends IGeneral {
  regionalSettings: IRegionalSettings[];
}

export interface ISingleRegionPortalSettings extends IGeneral {
  regionalSettings: IRegionalSettings;
}

export enum CancelOrderType {
  'not_shipped' = 'not_shipped',
  '24h' = '24h',
}
