import React, { VFC, useMemo } from 'react';

import { DisplayStep, OutvioStatusCodes, StepStatus } from '../../../../../Interfaces';

/**
 * sl = short line
 * ar = arrow head
 * md = substep line
 */

const Dot: VFC<{ step: DisplayStep }> = ({ step }) => {
  const { status, isSubStep, code } = step;
  const background = useMemo<string>(() => {
    /**
     * Substeps don't have dots
     */
    if (isSubStep) return '';
    switch (status) {
      case StepStatus.DONE:
      case StepStatus.INCIDENT:
        return 'bg-tp-primary';
      case StepStatus.BACKTRACK_INCIDENT:
      case StepStatus.BACKTRACK:
        return 'w-[8px] h-[8px] bg-tp-secondaryBg outline outline-2 outline-tp-primary';
      case StepStatus.PENDING:
      default:
        return 'bg-tp-primaryAlt';
    }
  }, [status, isSubStep]);

  const isDelshop = useMemo<boolean>(() => {
    return code === OutvioStatusCodes.DELIVERED_TO_SHOP;
  }, [code]);

  return (
    <div
      className={`m-auto right-0 left-0 w-[10px] h-[10px] absolute z-10 rounded-full ${background} ${
        isDelshop ? 'bottom-auto top-[18px]' : 'bottom-0 top-0'
      }`}
    />
  );
};

export default Dot;
