import React, { forwardRef, useContext } from 'react';

import { Theme, makeStyles } from '@material-ui/core';

import OVCStoreDataContext from './OVCStoreDataContext';

interface StyleProps {
  bgColor: string;
  formWidth: number;
}

// @ts-ignore
const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  backgroundBox: {
    width: '100%',
    maxWidth: 'fit-content',

    padding: '45px 50px',

    borderRadius: '5px',
    backgroundColor: (props) => `${props.bgColor}E6`, // 90% opacity

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      padding: '45px 15px',
      maxWidth: 'unset',
    },
  },
  formContainer: {
    width: (props) => `${props.formWidth}px`,

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },

    '& > form': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',

      '& > *': {
        marginBottom: '5px',

        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
  },
}));

interface IOVCAuthBox {
  children: React.ReactNode;
  formWidth?: number;
}

const OVCAuthBox = forwardRef<HTMLDivElement, IOVCAuthBox>(({ children, formWidth = 320 }, ref) => {
  const { storeData } = useContext(OVCStoreDataContext);
  const classes = useStyles({
    bgColor: storeData.portalSettings.colors.secondaryBg,
    formWidth,
  });

  return (
    <div className={classes.backgroundBox} ref={ref}>
      <div className={classes.formContainer}>{children}</div>
    </div>
  );
});

export default OVCAuthBox;
