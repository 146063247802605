export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: any; output: any };
  JSONObject: { input: any; output: any };
  ObjectID: { input: any; output: any };
};

export interface AddressObject {
  __typename?: 'AddressObject';
  address: Maybe<Scalars['String']['output']>;
  city: Maybe<Scalars['String']['output']>;
  contactPhone: Maybe<Scalars['String']['output']>;
  country: Maybe<Scalars['String']['output']>;
  postcode: Maybe<Scalars['String']['output']>;
  province: Maybe<Scalars['String']['output']>;
  state: Maybe<Scalars['String']['output']>;
}

export interface AttachmentInput {
  key: Scalars['String']['input'];
  originalFilename: Scalars['String']['input'];
  size: Scalars['Float']['input'];
  type: Scalars['String']['input'];
  url: Scalars['String']['input'];
}

export interface ChatAutomation {
  __typename?: 'ChatAutomation';
  findOrder: Scalars['Boolean']['output'];
  returnExchange: Scalars['Boolean']['output'];
}

export interface ChatAutomationInput {
  findOrder: Scalars['Boolean']['input'];
  returnExchange: Scalars['Boolean']['input'];
}

export interface ChatAvailabilityInput {
  days: InputMaybe<Array<InputMaybe<DaysInput>>>;
  timezone: InputMaybe<Scalars['String']['input']>;
}

export interface ChatAvailabilityObject {
  __typename?: 'ChatAvailabilityObject';
  brand: Scalars['String']['output'];
  days: Maybe<Array<Maybe<DaysObject>>>;
  id: Scalars['ID']['output'];
  timezone: Maybe<Scalars['String']['output']>;
}

export interface ChatConfigurationInput {
  automation: InputMaybe<ChatAutomationInput>;
  companyName: InputMaybe<Scalars['String']['input']>;
  languages: InputMaybe<Array<ChatLanguageInput>>;
  logo: InputMaybe<AttachmentInput>;
  offlineEmailId: InputMaybe<Scalars['String']['input']>;
  primaryColor: InputMaybe<Scalars['String']['input']>;
  secondaryColor: InputMaybe<Scalars['String']['input']>;
  supportTeam: InputMaybe<Scalars['String']['input']>;
}

export interface ChatConfigurationObject {
  __typename?: 'ChatConfigurationObject';
  automation: ChatAutomation;
  chatToken: Scalars['String']['output'];
  companyName: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  languages: Maybe<Array<Maybe<ChatLanguageObject>>>;
  logo: Maybe<MessageAttachmentObject>;
  offlineEmailId: Maybe<Scalars['String']['output']>;
  primaryColor: Maybe<Scalars['String']['output']>;
  secondaryColor: Maybe<Scalars['String']['output']>;
  supportTeam: Maybe<Scalars['String']['output']>;
}

export interface ChatLanguageInput {
  isDefault: Scalars['Boolean']['input'];
  language: Languages;
}

export interface ChatLanguageObject {
  __typename?: 'ChatLanguageObject';
  isDefault: Scalars['Boolean']['output'];
  language: Languages;
}

export interface ChatTranslationGetByTokenObject {
  __typename?: 'ChatTranslationGetByTokenObject';
  values: TranslationValuesObject;
}

export interface ChatTranslationInput {
  language: Languages;
  values: TranslationValuesInput;
}

export interface ChatTranslationObject {
  __typename?: 'ChatTranslationObject';
  id: Scalars['ID']['output'];
  language: Languages;
  values: TranslationValuesObject;
}

export interface CommentObject {
  __typename?: 'CommentObject';
  attachments: Array<Maybe<MessageAttachmentObject>>;
  body: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Maybe<Scalars['ID']['output']>;
  externalId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  parentId: Maybe<Scalars['String']['output']>;
  senderId: Maybe<Scalars['String']['output']>;
  senderName: Maybe<Scalars['String']['output']>;
  senderPhoto: Maybe<Scalars['String']['output']>;
  type: MessageTypes;
}

export interface CommentReplyByAgentInput {
  /** Comment attachments */
  attachments: InputMaybe<Array<AttachmentInput>>;
  /** Comment message body */
  body: Scalars['String']['input'];
  /** Replying comment externalId */
  externalId: Scalars['String']['input'];
  snoozeTimeUnit: InputMaybe<TimeUnit>;
  snoozeTimeValue: InputMaybe<Scalars['Float']['input']>;
  /** Ticket id */
  ticketId: Scalars['ID']['input'];
  /** New ticket-status */
  ticketStatus: InputMaybe<TicketStatuses>;
}

export interface CompanyFolderInput {
  brands: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  channels: InputMaybe<Array<InputMaybe<TicketChannels>>>;
  icon: InputMaybe<Scalars['String']['input']>;
  languages: InputMaybe<Array<InputMaybe<Languages>>>;
  name: Scalars['String']['input'];
  tags: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ticketAssignees: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  visibleFor: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export interface CourierContactInput {
  courierType: Scalars['String']['input'];
  emails: InputMaybe<Array<Scalars['String']['input']>>;
}

export interface CourierContactObject {
  __typename?: 'CourierContactObject';
  courierType: Scalars['String']['output'];
  emails: Maybe<Array<Emails>>;
  id: Scalars['String']['output'];
  store: Scalars['String']['output'];
}

export interface Couriers {
  __typename?: 'Couriers';
  email: Scalars['String']['output'];
  integration: Scalars['String']['output'];
}

export interface CustomerInteractionHistoryData {
  __typename?: 'CustomerInteractionHistoryData';
  date: Scalars['String']['output'];
  orderOtn: Maybe<Scalars['String']['output']>;
  ticketChannel: Maybe<TicketChannels>;
  ticketId: Maybe<Scalars['String']['output']>;
}

export interface CustomerInteractionHistoryObject {
  __typename?: 'CustomerInteractionHistoryObject';
  data: CustomerInteractionHistoryData;
  event: InteractionHistoryEvents;
}

export interface CustomerObject {
  __typename?: 'CustomerObject';
  addresses: Maybe<Array<Maybe<AddressObject>>>;
  firstname: Scalars['String']['output'];
  lastname: Scalars['String']['output'];
}

export interface CustomerOrderHistoryData {
  __typename?: 'CustomerOrderHistoryData';
  courierIntegrationName: Maybe<Scalars['String']['output']>;
  date: Scalars['String']['output'];
  lastTrackingStep: Maybe<Scalars['String']['output']>;
  orderId: Scalars['String']['output'];
  orderOtn: Scalars['String']['output'];
}

export interface CustomerOrderHistoryObject {
  __typename?: 'CustomerOrderHistoryObject';
  data: CustomerOrderHistoryData;
  event: OrderHistoryEvents;
}

export interface DaysInput {
  day: InputMaybe<Scalars['String']['input']>;
  from: InputMaybe<Scalars['String']['input']>;
  isEnabled: InputMaybe<Scalars['Boolean']['input']>;
  to: InputMaybe<Scalars['String']['input']>;
}

export interface DaysObject {
  __typename?: 'DaysObject';
  day: Maybe<Scalars['String']['output']>;
  from: Maybe<Scalars['String']['output']>;
  isEnabled: Maybe<Scalars['Boolean']['output']>;
  to: Maybe<Scalars['String']['output']>;
}

export enum DomainTypes {
  Custom = 'custom',
  Outvio = 'outvio',
}

export interface EmailConfigurationInput {
  brand: Scalars['String']['input'];
  customEmail: InputMaybe<Scalars['String']['input']>;
  displayEmailHeader: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  enableCustomEmail: Scalars['Boolean']['input'];
  senderName: Scalars['String']['input'];
}

export interface EmailConfigurationObject {
  __typename?: 'EmailConfigurationObject';
  /** Active email in use */
  activeEmail: Scalars['String']['output'];
  brand: Scalars['String']['output'];
  customEmail: Maybe<Scalars['String']['output']>;
  displayEmailHeader: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  emailVerified: Scalars['Boolean']['output'];
  enableCustomEmail: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  senderName: Scalars['String']['output'];
  store: Scalars['String']['output'];
}

export interface EmailConfigurationUpdateInput {
  customEmail: InputMaybe<Scalars['String']['input']>;
  displayEmailHeader: InputMaybe<Scalars['Boolean']['input']>;
  enableCustomEmail: Scalars['Boolean']['input'];
  senderName: Scalars['String']['input'];
}

export interface Emails {
  __typename?: 'Emails';
  domain: Scalars['String']['output'];
  email: Scalars['String']['output'];
}

export interface FolderMigrateClaimObject {
  __typename?: 'FolderMigrateClaimObject';
  count: Scalars['Int']['output'];
  ids: Array<Maybe<Scalars['ID']['output']>>;
}

export interface FolderObject {
  __typename?: 'FolderObject';
  brands: Maybe<Array<Maybe<Scalars['ObjectID']['output']>>>;
  channels: Maybe<Array<Maybe<TicketChannels>>>;
  icon: Maybe<Scalars['String']['output']>;
  id: Scalars['ObjectID']['output'];
  languages: Maybe<Array<Maybe<Languages>>>;
  name: Scalars['String']['output'];
  sortingIndex: Scalars['Float']['output'];
  tags: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ticketAssignees: Maybe<Array<Maybe<Scalars['ObjectID']['output']>>>;
  type: FolderTypes;
  visibleFor: Maybe<Array<Maybe<Scalars['ObjectID']['output']>>>;
}

export interface FolderTicketCountObject {
  __typename?: 'FolderTicketCountObject';
  count: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
}

export interface FolderTicketCountOneObject {
  __typename?: 'FolderTicketCountOneObject';
  count: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
}

export enum FolderTypes {
  Claim = 'claim',
  Company = 'company',
  Personal = 'personal',
  System = 'system',
}

export interface FoldersTicketCountObject {
  __typename?: 'FoldersTicketCountObject';
  data: Array<FolderTicketCountObject>;
}

export interface HighlightItem {
  __typename?: 'HighlightItem';
  path: Scalars['String']['output'];
  score: Scalars['Float']['output'];
  texts: Array<HighlightItemText>;
}

export interface HighlightItemText {
  __typename?: 'HighlightItemText';
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

export enum InteractionHistoryEvents {
  OrderCreated = 'order_created',
  ReturnCreated = 'return_created',
  TicketAgentReplied = 'ticket_agent_replied',
  TicketCreated = 'ticket_created',
}

export enum Languages {
  Bg = 'bg',
  Ca = 'ca',
  Cs = 'cs',
  Da = 'da',
  De = 'de',
  El = 'el',
  En = 'en',
  Es = 'es',
  Et = 'et',
  Fi = 'fi',
  Fr = 'fr',
  Ga = 'ga',
  Hr = 'hr',
  Hu = 'hu',
  It = 'it',
  Lt = 'lt',
  Lv = 'lv',
  Nl = 'nl',
  No = 'no',
  Pl = 'pl',
  Pt = 'pt',
  Ro = 'ro',
  Sk = 'sk',
  Sl = 'sl',
  Sv = 'sv',
}

export interface MessageAttachmentObject {
  __typename?: 'MessageAttachmentObject';
  key: Maybe<Scalars['String']['output']>;
  originalFilename: Maybe<Scalars['String']['output']>;
  size: Maybe<Scalars['Float']['output']>;
  type: Maybe<Scalars['String']['output']>;
  url: Maybe<Scalars['String']['output']>;
}

export interface MessageCallCommentInput {
  /** phone call identifier */
  callId: Scalars['String']['input'];
  /** reach editor body */
  message: Array<Scalars['JSONObject']['input']>;
}

export interface MessageCommentInput {
  /** attachments to comment */
  attachments: InputMaybe<Array<AttachmentInput>>;
  /** list of agents ids that has been mentioned in the comment */
  mentions: InputMaybe<Array<Scalars['String']['input']>>;
  /** message id */
  messageId: Scalars['String']['input'];
  /** comment body text */
  text: Array<Scalars['JSONObject']['input']>;
}

export interface MessageCommentObject {
  __typename?: 'MessageCommentObject';
  attachments: Array<Maybe<MessageAttachmentObject>>;
  createdAt: Scalars['DateTime']['output'];
  /** created by agent id */
  createdBy: Scalars['String']['output'];
  /** list of agents ids that has been mentioned in the comment */
  mentions: Maybe<Array<Scalars['String']['output']>>;
  /** message id */
  message: Maybe<Scalars['String']['output']>;
  /** comment body text */
  text: Array<Scalars['JSONObject']['output']>;
  /** ticket id */
  ticket: Scalars['String']['output'];
}

export interface MessageObject {
  __typename?: 'MessageObject';
  attachments: Maybe<Array<MessageAttachmentObject>>;
  body: Maybe<Scalars['String']['output']>;
  /** reach text body */
  bodyJson: Maybe<Array<Scalars['JSONObject']['output']>>;
  cc: Maybe<Array<Scalars['String']['output']>>;
  contextData: Maybe<MessageVoiceContextDataObject>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Maybe<Scalars['String']['output']>;
  error: Maybe<Scalars['String']['output']>;
  fromEmail: Maybe<Scalars['String']['output']>;
  fromSenderName: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  source: MessageSources;
  status: MessageStatuses;
  ticket: Scalars['String']['output'];
  toEmail: Maybe<Scalars['String']['output']>;
  toSenderName: Maybe<Scalars['String']['output']>;
  type: MessageTypes;
}

export interface MessageReplyByAgentInput {
  /** attachments to client message */
  attachments: InputMaybe<Array<AttachmentInput>>;
  /** reach editor body */
  bodyJson: Array<Scalars['JSONObject']['input']>;
  cc: InputMaybe<Array<Scalars['String']['input']>>;
  snoozeTimeUnit: InputMaybe<TimeUnit>;
  snoozeTimeValue: InputMaybe<Scalars['Float']['input']>;
  /** Ticket id */
  ticketId: Scalars['String']['input'];
  /** update ticket status */
  ticketStatus: InputMaybe<TicketStatuses>;
}

export interface MessageReplyBySupportInput {
  /** attachments to client message */
  attachments: InputMaybe<Array<AttachmentInput>>;
  /** client message body */
  body: Scalars['String']['input'];
  /** Ticket id */
  ticketId: Scalars['String']['input'];
}

export enum MessageSources {
  Chat = 'chat',
  Email = 'email',
  Facebook = 'facebook',
  FacebookComment = 'facebookComment',
  Instagram = 'instagram',
  InstagramComment = 'instagramComment',
  Support = 'support',
  Voice = 'voice',
  Whatsapp = 'whatsapp',
}

export enum MessageStatuses {
  Created = 'created',
  Error = 'error',
  Sent = 'sent',
}

export enum MessageTypes {
  Agent = 'agent',
  Recipient = 'recipient',
  Rule = 'rule',
}

export interface MessageVoiceContextDataObject {
  __typename?: 'MessageVoiceContextDataObject';
  durationSeconds: Maybe<Scalars['Float']['output']>;
  event: MessageVoiceEvents;
  triggeredBy: Maybe<MessageVoiceTriggeredBy>;
}

export enum MessageVoiceEvents {
  CancelledCall = 'cancelledCall',
  EndedCall = 'endedCall',
  IncomingCall = 'incomingCall',
  MissedCall = 'missedCall',
  OutgoingCall = 'outgoingCall',
}

export enum MessageVoiceTriggeredBy {
  Agent = 'agent',
  User = 'user',
}

export enum MessengerPermissions {
  InstagramBasic = 'instagramBasic',
  InstagramManageMessages = 'instagramManageMessages',
  PagesManageEngagement = 'pagesManageEngagement',
  PagesManageMetadata = 'pagesManageMetadata',
  PagesMessaging = 'pagesMessaging',
  PagesReadUserContent = 'pagesReadUserContent',
  PagesShowList = 'pagesShowList',
  PagesUserLocale = 'pagesUserLocale',
  PagesUserTimezone = 'pagesUserTimezone',
  PublicProfile = 'publicProfile',
}

export interface MetaCount {
  __typename?: 'MetaCount';
  total: Scalars['Float']['output'];
}

export interface MetaSearch {
  __typename?: 'MetaSearch';
  count: MetaCount;
}

export interface MigrateCourierObject {
  __typename?: 'MigrateCourierObject';
  result: Array<Result>;
  success: Scalars['Boolean']['output'];
}

export interface Mutation {
  __typename?: 'Mutation';
  ChatAvailability_Update: Maybe<ChatAvailabilityObject>;
  ChatConfiguration_UpdateOne: ChatConfigurationObject;
  ChatTranslation_UpsertMany: Array<ChatTranslationObject>;
  Comment_ReplyByAgent: CommentObject;
  /** Creates courier contact per store */
  CourierContact_Create: CourierContactObject;
  CourierContact_DeleteOne: Scalars['Boolean']['output'];
  CourierContact_DetectByEmail: Maybe<CourierContactObject>;
  CourierContact_MigrateCouriers: MigrateCourierObject;
  /** Update courier contact per store */
  CourierContact_Update: CourierContactObject;
  /** Creates email-configuration */
  EmailConfiguration_CreateOne: EmailConfigurationObject;
  /** Delete email-configuration */
  EmailConfiguration_DeleteOne: Scalars['Boolean']['output'];
  EmailConfiguration_Migrate: Scalars['Boolean']['output'];
  /** Update email-configuration */
  EmailConfiguration_UpdateOne: EmailConfigurationObject;
  Folder_CreateOneCompany: FolderObject;
  Folder_CreateOnePersonal: FolderObject;
  Folder_DeleteOne: Scalars['Boolean']['output'];
  /** Creates a duplicate */
  Folder_Duplicate: FolderObject;
  Folder_MigrateClaim: FolderMigrateClaimObject;
  /** Swaps two folders order indices */
  Folder_SwapIndexes: Scalars['Boolean']['output'];
  Folder_UpdateCompanyFolder: FolderObject;
  Folder_UpdatePersonalFolder: FolderObject;
  MessageComment_Create: MessageCommentObject;
  MessageComment_CreateByCallId: MessageCommentObject;
  Message_ReplyByAgent: MessageObject;
  Message_ReplyBySupport: MessageObject;
  /** Creates or updates records */
  RatingPage_batchUpsert: Array<RatingPageObject>;
  RatingPage_rateByToken: RateByTokenObject;
  RatingRule_update: RatingRuleSettingsObject;
  Rule_CreateOne: RuleObject;
  Rule_DeleteOne: Scalars['Boolean']['output'];
  /** Creates a duplicate */
  Rule_Duplicate: RuleObject;
  /** Swaps two rules order indices */
  Rule_SwapIndexes: Scalars['Boolean']['output'];
  Rule_UpdateOne: RuleObject;
  /** Creates a tag */
  Store_AddTag: StoreObject;
  /** Finish desk onboarding */
  Store_FinishOnboarding: StoreObject;
  Store_MessengerDeAuth: Scalars['Boolean']['output'];
  Store_MessengerDeletePage: Scalars['Boolean']['output'];
  /** Removes a tag */
  Store_RemoveTag: Scalars['Boolean']['output'];
  /** Updates a store configuration */
  Store_UpdateConfiguration: StoreObject;
  /** Replaces/creates store tags */
  Store_UpdateTags: Scalars['Boolean']['output'];
  /** Creates a new template */
  Team_CreateOne: TeamObject;
  Team_DeleteOne: Scalars['Boolean']['output'];
  /** Updates a template */
  Team_UpdateOne: TeamObject;
  Template_CreateOne: TemplateObject;
  Template_DeleteOne: Scalars['Boolean']['output'];
  Template_Duplicate: TemplateObject;
  Template_UpdateOne: TemplateObject;
  TicketComment_Create: MessageCommentObject;
  /** Adds a current user to ticket watchers list */
  Ticket_AddWatcher: TicketObject;
  /** Add tags for multiple tickets */
  Ticket_BatchAddTags: Scalars['Boolean']['output'];
  /** Batch Assignee multiple tickets */
  Ticket_BatchAssignee: Scalars['Boolean']['output'];
  /** Batch Follow multiple tickets */
  Ticket_BatchFollow: Scalars['Boolean']['output'];
  Ticket_CloseOne: TicketObject;
  Ticket_CloseOneSupport: TicketObject;
  /** Create ticket from UI interface */
  Ticket_CreateByAgent: TicketObject;
  /** Create support ticket from UI interface */
  Ticket_CreateSupport: TicketObject;
  /** Forward voice call to another agent */
  Ticket_ForwardVoiceCall: TicketObject;
  /** Updates date when ticket has been seen by agent */
  Ticket_HasBeenSeen: TicketObject;
  /** Removes a ticket tags */
  Ticket_RemoveTags: TicketObject;
  /** Removes a user from ticket watchers */
  Ticket_RemoveWatcher: TicketObject;
  /** Save user input as a draft */
  Ticket_SaveDraft: Scalars['Boolean']['output'];
  /** Sets ticket assignee */
  Ticket_SetAssignee: TicketObject;
  /** Sets tickets order information */
  Ticket_SetOrder: TicketObject;
  /** Sets tickets status */
  Ticket_SetStatus: Scalars['Boolean']['output'];
  /** Write ticket satisfaction review */
  Ticket_SetSupportReview: TicketObject;
  /** Set ticket tags */
  Ticket_SetTags: TicketObject;
  /** Forward voice call to another agent */
  Voice_ForwardCall: VoiceObject;
}

export type MutationChatAvailability_UpdateArgs = {
  brandId: Scalars['ID']['input'];
  input: ChatAvailabilityInput;
};

export type MutationChatConfiguration_UpdateOneArgs = {
  brandId: Scalars['ID']['input'];
  input: ChatConfigurationInput;
};

export type MutationChatTranslation_UpsertManyArgs = {
  brandId: Scalars['ID']['input'];
  translations: Array<ChatTranslationInput>;
};

export type MutationComment_ReplyByAgentArgs = {
  input: CommentReplyByAgentInput;
};

export type MutationCourierContact_CreateArgs = {
  input: CourierContactInput;
};

export type MutationCourierContact_DeleteOneArgs = {
  id: Scalars['String']['input'];
};

export type MutationCourierContact_DetectByEmailArgs = {
  email: Scalars['String']['input'];
};

export type MutationCourierContact_UpdateArgs = {
  input: CourierContactInput;
};

export type MutationEmailConfiguration_CreateOneArgs = {
  input: EmailConfigurationInput;
};

export type MutationEmailConfiguration_DeleteOneArgs = {
  id: Scalars['String']['input'];
};

export type MutationEmailConfiguration_MigrateArgs = {
  flush: Scalars['Boolean']['input'];
};

export type MutationEmailConfiguration_UpdateOneArgs = {
  id: Scalars['String']['input'];
  input: EmailConfigurationUpdateInput;
};

export type MutationFolder_CreateOneCompanyArgs = {
  input: CompanyFolderInput;
};

export type MutationFolder_CreateOnePersonalArgs = {
  input: PersonalFolderInput;
};

export type MutationFolder_DeleteOneArgs = {
  id: Scalars['String']['input'];
};

export type MutationFolder_DuplicateArgs = {
  id: Scalars['String']['input'];
};

export type MutationFolder_MigrateClaimArgs = {
  dryRun: Scalars['Boolean']['input'];
};

export type MutationFolder_SwapIndexesArgs = {
  ids: Array<Scalars['String']['input']>;
};

export type MutationFolder_UpdateCompanyFolderArgs = {
  id: Scalars['String']['input'];
  input: UpdateCompanyFolderInput;
};

export type MutationFolder_UpdatePersonalFolderArgs = {
  id: Scalars['String']['input'];
  input: UpdatePersonalFolderInput;
};

export type MutationMessageComment_CreateArgs = {
  input: MessageCommentInput;
};

export type MutationMessageComment_CreateByCallIdArgs = {
  input: MessageCallCommentInput;
};

export type MutationMessage_ReplyByAgentArgs = {
  input: MessageReplyByAgentInput;
};

export type MutationMessage_ReplyBySupportArgs = {
  input: MessageReplyBySupportInput;
};

export type MutationRatingPage_BatchUpsertArgs = {
  brandId: Scalars['String']['input'];
  input: Array<RatingPageInput>;
};

export type MutationRatingPage_RateByTokenArgs = {
  rating: Scalars['Float']['input'];
  token: Scalars['String']['input'];
};

export type MutationRatingRule_UpdateArgs = {
  brandId: Scalars['String']['input'];
  input: RatingRuleSettingsInput;
};

export type MutationRule_CreateOneArgs = {
  input: RuleInput;
};

export type MutationRule_DeleteOneArgs = {
  id: Scalars['String']['input'];
};

export type MutationRule_DuplicateArgs = {
  id: Scalars['String']['input'];
};

export type MutationRule_SwapIndexesArgs = {
  ids: Array<Scalars['String']['input']>;
};

export type MutationRule_UpdateOneArgs = {
  id: Scalars['String']['input'];
  input: RuleInput;
};

export type MutationStore_AddTagArgs = {
  tag: Scalars['String']['input'];
};

export type MutationStore_MessengerDeletePageArgs = {
  pageId: Scalars['String']['input'];
};

export type MutationStore_RemoveTagArgs = {
  tag: Scalars['String']['input'];
};

export type MutationStore_UpdateConfigurationArgs = {
  input: StoreInput;
};

export type MutationStore_UpdateTagsArgs = {
  tags: Array<InputMaybe<Scalars['String']['input']>>;
};

export type MutationTeam_CreateOneArgs = {
  input: TeamInput;
};

export type MutationTeam_DeleteOneArgs = {
  id: Scalars['String']['input'];
};

export type MutationTeam_UpdateOneArgs = {
  id: Scalars['String']['input'];
  input: TeamInput;
};

export type MutationTemplate_CreateOneArgs = {
  input: TemplateInput;
};

export type MutationTemplate_DeleteOneArgs = {
  id: Scalars['String']['input'];
};

export type MutationTemplate_DuplicateArgs = {
  id: Scalars['String']['input'];
};

export type MutationTemplate_UpdateOneArgs = {
  id: Scalars['String']['input'];
  input: TemplateInput;
};

export type MutationTicketComment_CreateArgs = {
  input: TicketCommentInput;
};

export type MutationTicket_AddWatcherArgs = {
  id: Scalars['String']['input'];
};

export type MutationTicket_BatchAddTagsArgs = {
  tags: Array<Scalars['String']['input']>;
  ticketIds: Array<Scalars['String']['input']>;
};

export type MutationTicket_BatchAssigneeArgs = {
  AssigneeId: Scalars['String']['input'];
  ticketIds: Array<Scalars['String']['input']>;
};

export type MutationTicket_BatchFollowArgs = {
  ticketIds: Array<Scalars['String']['input']>;
};

export type MutationTicket_CloseOneArgs = {
  id: Scalars['String']['input'];
};

export type MutationTicket_CloseOneSupportArgs = {
  id: Scalars['String']['input'];
};

export type MutationTicket_CreateByAgentArgs = {
  input: TicketCreateByAgentInput;
};

export type MutationTicket_CreateSupportArgs = {
  input: TicketCreateBySupportInput;
};

export type MutationTicket_ForwardVoiceCallArgs = {
  agentId: Scalars['String']['input'];
  callId: Scalars['String']['input'];
};

export type MutationTicket_HasBeenSeenArgs = {
  ticketId: Scalars['String']['input'];
};

export type MutationTicket_RemoveTagsArgs = {
  tags: Array<Scalars['String']['input']>;
  ticketId: Scalars['String']['input'];
};

export type MutationTicket_RemoveWatcherArgs = {
  id: Scalars['String']['input'];
};

export type MutationTicket_SaveDraftArgs = {
  input: TicketDraftInput;
};

export type MutationTicket_SetAssigneeArgs = {
  assignee: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

export type MutationTicket_SetOrderArgs = {
  input: SetTicketOrderInput;
};

export type MutationTicket_SetStatusArgs = {
  input: SetStatusInput;
};

export type MutationTicket_SetSupportReviewArgs = {
  input: TicketSetReviewInput;
};

export type MutationTicket_SetTagsArgs = {
  tags: Array<Scalars['String']['input']>;
  ticketId: Scalars['String']['input'];
};

export type MutationVoice_ForwardCallArgs = {
  agentId: Scalars['String']['input'];
  callId: Scalars['String']['input'];
};

export enum OrderHistoryEvents {
  OrderCreated = 'order_created',
  OrderDelivered = 'order_delivered',
  ReturnCreated = 'return_created',
  ReturnDelivered = 'return_delivered',
  ReturnShipmentCancelled = 'return_shipment_cancelled',
  ReturnShipmentCreated = 'return_shipment_created',
  ShipmentCancelled = 'shipment_cancelled',
  ShipmentCreated = 'shipment_created',
}

export interface PersonalFolderInput {
  brands: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  channels: InputMaybe<Array<InputMaybe<TicketChannels>>>;
  icon: InputMaybe<Scalars['String']['input']>;
  languages: InputMaybe<Array<InputMaybe<Languages>>>;
  name: Scalars['String']['input'];
  tags: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export interface Query {
  __typename?: 'Query';
  ChatAvailability_GetByBrand: Maybe<ChatAvailabilityObject>;
  ChatConfiguration_GetByBrand: Maybe<ChatConfigurationObject>;
  ChatTranslation_GetByBrand: Array<Maybe<ChatTranslationObject>>;
  ChatTranslation_GetByToken: Maybe<ChatTranslationGetByTokenObject>;
  /** Gets all comments by ticket id */
  Comment_FindAllByTicketId: Array<Maybe<CommentObject>>;
  /** Gets store courier contact list */
  CourierContact_List: Array<Maybe<CourierContactObject>>;
  /** Gets store email-configurations list */
  EmailConfiguration_List: Array<Maybe<EmailConfigurationObject>>;
  /** Gets all company folders settings for the store */
  Folder_CompanyList: Array<Maybe<FolderObject>>;
  /** Gets folders tickets count */
  Folder_CountAll: FoldersTicketCountObject;
  /** Gets folder tickets count */
  Folder_CountOne: FolderTicketCountOneObject;
  /** Gets folders visible for current user */
  Folder_List: Array<Maybe<FolderObject>>;
  /** Gets personal folders settings visible for the current user */
  Folder_PersonalList: Array<Maybe<FolderObject>>;
  MediaFile_GetSignedUrl: Scalars['String']['output'];
  /** Get all message by ticket id  */
  MessageComment_FindAllByTicketId: Maybe<Array<MessageCommentObject>>;
  /** Get all comments by ticket id */
  Message_FindAllBySupportTicketId: Maybe<Array<MessageObject>>;
  /** Get all comments by ticket id */
  Message_FindAllByTicketId: Maybe<Array<MessageObject>>;
  RatingPage_FindByBrand: Array<Maybe<RatingPageObject>>;
  RatingPage_FindByChatToken: Maybe<RatingPageByTokenObject>;
  RatingPage_FindByToken: RatingPageByTokenObject;
  RatingRule_GetSettings: RatingRuleSettingsObject;
  /** Rule condition schema */
  Rule_GetSchema: RuleObjectSchema;
  /** Gets all rules */
  Rule_List: Array<Maybe<RuleObject>>;
  /** Searches for tickets, messages, and customers */
  Search: SearchObject;
  Store_MessengerGetOAuth: Maybe<Scalars['String']['output']>;
  Store_MessengerGetPages: Maybe<Array<StoreMessengerPageObject>>;
  Store_MessengerGetPermissions: Array<StoreMessengerPermissionObject>;
  /** Gets current user store */
  Store_MyStore: Maybe<StoreObject>;
  /** Gets the list of store teams */
  Team_List: Array<Maybe<TeamObject>>;
  /** Apply template variables */
  Template_GetTemplateWithVariables: TemplateWithVariablesObject;
  /** Gets store templates */
  Template_List: Array<Maybe<TemplateObject>>;
  /** Gets template variables */
  Template_Variables: TemplateVariablesObject;
  /** Get all ticket logs ticket id */
  TicketLog_FindByTicket: Maybe<Array<TicketLogObject>>;
  /** Count Tickets by status for Outvio support page */
  Ticket_CountByClientStore: Scalars['Float']['output'];
  Ticket_CustomerByEmail: Maybe<CustomerObject>;
  Ticket_CustomerInteractionHistory: Array<Maybe<CustomerInteractionHistoryObject>>;
  Ticket_CustomerOrderHistory: Array<Maybe<CustomerOrderHistoryObject>>;
  /** Gets customer related tickets */
  Ticket_CustomerRelatedTickets: Array<Maybe<TicketObject>>;
  /** Gets one ticket by id */
  Ticket_GetOne: Maybe<TicketObject>;
  /** Gets one ticket by call id */
  Ticket_GetOneByCall: Maybe<TicketObject>;
  /** Gets one support ticket by id */
  Ticket_GetOneSupport: Maybe<TicketObject>;
  /** Gets all tickets by folder */
  Ticket_List: Array<Maybe<TicketObject>>;
  /** Gets all support tickets */
  Ticket_ListSupport: Array<Maybe<TicketObject>>;
  /** Get list of available phone numbers (brands) */
  Voice_ListPhoneNumbers: Array<VoicePhoneNumberObject>;
}

export type QueryChatAvailability_GetByBrandArgs = {
  brandId: Scalars['ID']['input'];
};

export type QueryChatConfiguration_GetByBrandArgs = {
  brandId: Scalars['ID']['input'];
};

export type QueryChatTranslation_GetByBrandArgs = {
  brandId: Scalars['ID']['input'];
  languages: Array<Languages>;
};

export type QueryChatTranslation_GetByTokenArgs = {
  language: Languages;
  token: Scalars['String']['input'];
};

export type QueryComment_FindAllByTicketIdArgs = {
  ticketId: Scalars['ID']['input'];
};

export type QueryFolder_CountOneArgs = {
  id: Scalars['String']['input'];
  status: InputMaybe<TicketStatuses>;
};

export type QueryMediaFile_GetSignedUrlArgs = {
  key: Scalars['String']['input'];
};

export type QueryMessageComment_FindAllByTicketIdArgs = {
  ticketId: Scalars['String']['input'];
};

export type QueryMessage_FindAllBySupportTicketIdArgs = {
  ticketId: Scalars['String']['input'];
};

export type QueryMessage_FindAllByTicketIdArgs = {
  ticketId: Scalars['String']['input'];
};

export type QueryRatingPage_FindByBrandArgs = {
  brandId: Scalars['String']['input'];
};

export type QueryRatingPage_FindByChatTokenArgs = {
  token: Scalars['String']['input'];
};

export type QueryRatingPage_FindByTokenArgs = {
  token: Scalars['String']['input'];
};

export type QueryRatingRule_GetSettingsArgs = {
  brandId: Scalars['String']['input'];
};

export type QuerySearchArgs = {
  limit: InputMaybe<Scalars['Float']['input']>;
  query: Scalars['String']['input'];
  searchAfter: InputMaybe<Scalars['String']['input']>;
};

export type QueryStore_MessengerGetOAuthArgs = {
  permissions: InputMaybe<Array<InputMaybe<MessengerPermissions>>>;
};

export type QueryTemplate_GetTemplateWithVariablesArgs = {
  templateId: Scalars['String']['input'];
  ticketId: InputMaybe<Scalars['String']['input']>;
};

export type QueryTemplate_ListArgs = {
  language: InputMaybe<Languages>;
};

export type QueryTicketLog_FindByTicketArgs = {
  ticketId: Scalars['String']['input'];
};

export type QueryTicket_CountByClientStoreArgs = {
  status: TicketSupportStatuses;
};

export type QueryTicket_CustomerByEmailArgs = {
  customerEmail: Scalars['String']['input'];
};

export type QueryTicket_CustomerInteractionHistoryArgs = {
  customerEmail: Scalars['String']['input'];
};

export type QueryTicket_CustomerOrderHistoryArgs = {
  customerEmail: Scalars['String']['input'];
};

export type QueryTicket_CustomerRelatedTicketsArgs = {
  customerEmail: Scalars['String']['input'];
};

export type QueryTicket_GetOneArgs = {
  id: InputMaybe<Scalars['String']['input']>;
  ticketId: InputMaybe<Scalars['String']['input']>;
};

export type QueryTicket_GetOneByCallArgs = {
  callId: Scalars['String']['input'];
};

export type QueryTicket_GetOneSupportArgs = {
  id: InputMaybe<Scalars['String']['input']>;
  ticketId: InputMaybe<Scalars['String']['input']>;
};

export type QueryTicket_ListArgs = {
  after: InputMaybe<Scalars['Int']['input']>;
  folder: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  status: TicketStatuses;
};

export type QueryTicket_ListSupportArgs = {
  after: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  status: InputMaybe<TicketSupportStatuses>;
};

export interface RateByTokenObject {
  __typename?: 'RateByTokenObject';
  disable: Scalars['Boolean']['output'];
}

export interface RatingPageByTokenObject {
  __typename?: 'RatingPageByTokenObject';
  buttonText: Scalars['String']['output'];
  currentValue: Maybe<Scalars['Float']['output']>;
  description: Scalars['String']['output'];
  disabled: Scalars['Boolean']['output'];
  id: Scalars['ObjectID']['output'];
  language: Scalars['String']['output'];
  logo: Scalars['String']['output'];
  title: Scalars['String']['output'];
}

export interface RatingPageInput {
  buttonText: Scalars['String']['input'];
  description: Scalars['String']['input'];
  language: Scalars['String']['input'];
  title: Scalars['String']['input'];
}

export interface RatingPageObject {
  __typename?: 'RatingPageObject';
  buttonText: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ObjectID']['output'];
  language: Scalars['String']['output'];
  title: Scalars['String']['output'];
}

export interface RatingRuleSettingsInput {
  ratingEnabled: Scalars['Boolean']['input'];
  timeUnit: InputMaybe<TimeUnit>;
  timeValue: InputMaybe<Scalars['Float']['input']>;
  whenTime: WhenTimeOptions;
}

export interface RatingRuleSettingsObject {
  __typename?: 'RatingRuleSettingsObject';
  id: Maybe<Scalars['ObjectID']['output']>;
  ratingEnabled: Scalars['Boolean']['output'];
  timeUnit: TimeUnit;
  timeValue: Scalars['Float']['output'];
  whenTime: WhenTimeOptions;
}

export interface Result {
  __typename?: 'Result';
  couriers: Array<Couriers>;
  store: Scalars['String']['output'];
}

export interface RuleConditionInput {
  /** Rule conditions */
  conditions: Array<RuleSubConditionInput>;
  /** Logic operation */
  logOp: RuleLogicOps;
}

export interface RuleConditionObject {
  __typename?: 'RuleConditionObject';
  conditions: Array<RuleSubConditionObject>;
  logOp: RuleLogicOps;
}

export enum RuleEvents {
  AgentReplied = 'agentReplied',
  RecipientNotReplied = 'recipientNotReplied',
  RecipientReplied = 'recipientReplied',
  StatusChanged = 'statusChanged',
  TicketCreated = 'ticketCreated',
}

export interface RuleInput {
  /** Assigning teams */
  assigneeTeams: InputMaybe<Array<Scalars['String']['input']>>;
  /** Assigning agents */
  assignees: InputMaybe<Array<Scalars['String']['input']>>;
  /** Rule condition */
  condition: RuleConditionInput;
  /** Trigger events */
  events: Array<RuleEvents>;
  /** Rule status */
  isActive: InputMaybe<Scalars['Boolean']['input']>;
  /** Rule name (Alias) */
  name: Scalars['String']['input'];
  snoozeTimeUnit: InputMaybe<TimeUnit>;
  snoozeTimeValue: InputMaybe<Scalars['Float']['input']>;
  /** Ticket tags */
  tags: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Automatic reply template */
  template: InputMaybe<Scalars['String']['input']>;
  /** Ticket status */
  ticketStatus: InputMaybe<TicketStatuses>;
  whenDelay: InputMaybe<Scalars['Float']['input']>;
  whenDelayUnit: InputMaybe<TimeUnit>;
}

export enum RuleLogicOps {
  And = 'and',
  Or = 'or',
}

export interface RuleObject {
  __typename?: 'RuleObject';
  assigneeTeams: Maybe<Array<Scalars['ObjectID']['output']>>;
  assignees: Maybe<Array<Scalars['ObjectID']['output']>>;
  condition: RuleConditionObject;
  events: Array<RuleEvents>;
  id: Scalars['ObjectID']['output'];
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  snoozeTimeUnit: Maybe<TimeUnit>;
  snoozeTimeValue: Maybe<Scalars['Float']['output']>;
  tags: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  template: Maybe<Scalars['ObjectID']['output']>;
  ticketStatus: Maybe<TicketStatuses>;
  whenDelay: Maybe<Scalars['Float']['output']>;
  whenDelayUnit: Maybe<TimeUnit>;
}

export interface RuleObjectSchema {
  __typename?: 'RuleObjectSchema';
  /** Return rule condition schema */
  events: Array<RuleEvents>;
  /** Return rule condition schema */
  schema: Scalars['JSONObject']['output'];
}

export enum RuleOps {
  Between = 'between',
  Contains = 'contains',
  ContainsAll = 'containsAll',
  Equal = 'equal',
  GreaterThan = 'greaterThan',
  Includes = 'includes',
  LessThan = 'lessThan',
  NotContains = 'notContains',
  NotEqual = 'notEqual',
  NotIncludes = 'notIncludes',
  NotStartsWith = 'notStartsWith',
  StartsWith = 'startsWith',
}

export interface RuleSubConditionInput {
  /** Nested group conditions */
  conditions: InputMaybe<Array<RuleSubSubConditionInput>>;
  /** Logic operation */
  logOp: InputMaybe<RuleLogicOps>;
  /** Condition operation */
  op: InputMaybe<RuleOps>;
  /** Condition param */
  param: InputMaybe<Scalars['String']['input']>;
  /** Condition value. JSON string */
  value: InputMaybe<Scalars['String']['input']>;
}

export interface RuleSubConditionObject {
  __typename?: 'RuleSubConditionObject';
  conditions: Maybe<Array<RuleSubSubConditionObject>>;
  logOp: Maybe<RuleLogicOps>;
  op: Maybe<RuleOps>;
  param: Maybe<Scalars['String']['output']>;
  value: Maybe<Scalars['String']['output']>;
}

export interface RuleSubSubConditionInput {
  /** Condition operation */
  op: RuleOps;
  /** Condition param */
  param: Scalars['String']['input'];
  /** Condition value. JSON string */
  value: Scalars['String']['input'];
}

export interface RuleSubSubConditionObject {
  __typename?: 'RuleSubSubConditionObject';
  op: RuleOps;
  param: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

export interface SearchObject {
  __typename?: 'SearchObject';
  count: Scalars['Float']['output'];
  results: Array<SearchTicketObject>;
  success: Scalars['Boolean']['output'];
}

export interface SearchTicketObject {
  __typename?: 'SearchTicketObject';
  assignee: Maybe<Scalars['ObjectID']['output']>;
  brand: Maybe<Scalars['ObjectID']['output']>;
  callId: Maybe<Array<Scalars['String']['output']>>;
  cc: Maybe<Array<Scalars['String']['output']>>;
  channel: TicketChannels;
  clientStore: Maybe<Scalars['String']['output']>;
  closedAt: Maybe<Scalars['DateTime']['output']>;
  closedBy: Maybe<Scalars['ObjectID']['output']>;
  courierType: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Maybe<Scalars['ObjectID']['output']>;
  /** Customer related tickets */
  customerRelatedTickets: Array<Maybe<TicketObject>>;
  draft: Maybe<TicketDraftObject>;
  hasBeenSeenByAgent: Maybe<Scalars['DateTime']['output']>;
  highlights: Array<HighlightItem>;
  id: Scalars['ObjectID']['output'];
  language: Maybe<Scalars['String']['output']>;
  lastRecipientResponseDate: Maybe<Scalars['DateTime']['output']>;
  manipulated: Scalars['Boolean']['output'];
  messagePreview: Scalars['String']['output'];
  messages: Maybe<Array<Maybe<MessageObject>>>;
  meta: MetaSearch;
  order: Maybe<TicketOrderObject>;
  orders: Maybe<Array<TicketOrderObject>>;
  paginationToken: Scalars['String']['output'];
  post: Maybe<TicketPostObject>;
  rating: Maybe<Scalars['Float']['output']>;
  recipientEmail: Maybe<Scalars['String']['output']>;
  recipientId: Maybe<Scalars['String']['output']>;
  recipientName: Scalars['String']['output'];
  recipientPhoto: Maybe<Scalars['String']['output']>;
  relatedTickets: Maybe<Array<Maybe<TicketObject>>>;
  returnId: Maybe<Scalars['String']['output']>;
  review: Maybe<Scalars['Float']['output']>;
  reviewComment: Maybe<Scalars['String']['output']>;
  score: Scalars['Float']['output'];
  status: TicketStatuses;
  subject: Scalars['String']['output'];
  supportEmail: Scalars['String']['output'];
  supportStatus: Maybe<TicketSupportStatuses>;
  supportSubject: Maybe<Scalars['String']['output']>;
  tags: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ticketId: Scalars['String']['output'];
  type: TicketTypes;
  updatedAt: Scalars['DateTime']['output'];
  waitingForRecipientResponse: Scalars['Boolean']['output'];
  watchers: Maybe<Array<Maybe<Scalars['ObjectID']['output']>>>;
}

export interface SetStatusInput {
  isSupport: InputMaybe<Scalars['Boolean']['input']>;
  snoozeTimeUnit: InputMaybe<TimeUnit>;
  snoozeTimeValue: InputMaybe<Scalars['Float']['input']>;
  status: TicketStatuses;
  tickets: Array<Scalars['String']['input']>;
}

export interface SetTicketOrderInput {
  order: InputMaybe<TicketOrderInput>;
  storeId: Scalars['String']['input'];
  ticketId: Scalars['String']['input'];
}

export interface StoreInput {
  domainType: InputMaybe<DomainTypes>;
  senderName: InputMaybe<Scalars['String']['input']>;
}

export interface StoreMessengerPageObject {
  __typename?: 'StoreMessengerPageObject';
  about: Maybe<Scalars['String']['output']>;
  hasInstagramAccount: Maybe<Scalars['Boolean']['output']>;
  hasPermissionIssues: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  link: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  permissionIssues: Maybe<StoreMessengerPagePermission>;
}

export interface StoreMessengerPagePermission {
  __typename?: 'StoreMessengerPagePermission';
  permission: Scalars['String']['output'];
  status: Scalars['String']['output'];
}

export interface StoreMessengerPermissionObject {
  __typename?: 'StoreMessengerPermissionObject';
  permission: Scalars['String']['output'];
  status: Scalars['String']['output'];
}

export interface StoreObject {
  __typename?: 'StoreObject';
  /** The list of supported channels */
  channels: Array<TicketChannels>;
  clientCode: Scalars['String']['output'];
  domainType: DomainTypes;
  id: Scalars['ObjectID']['output'];
  /** The list of supported languages */
  languages: Array<Scalars['String']['output']>;
  onboardingCompleted: Scalars['Boolean']['output'];
  senderName: Maybe<Scalars['String']['output']>;
  supportEmail: Scalars['String']['output'];
  tags: Maybe<Array<Maybe<Scalars['String']['output']>>>;
}

export interface TeamInput {
  agents: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  icon: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
}

export interface TeamObject {
  __typename?: 'TeamObject';
  agents: Maybe<Array<Maybe<Scalars['ObjectID']['output']>>>;
  icon: Maybe<Scalars['String']['output']>;
  id: Scalars['ObjectID']['output'];
  name: Scalars['String']['output'];
}

export interface TemplateInput {
  /** reach editor body */
  bodyJson: Array<Scalars['JSONObject']['input']>;
  icon: InputMaybe<Scalars['String']['input']>;
  language: Languages;
  subject: Scalars['String']['input'];
}

export interface TemplateObject {
  __typename?: 'TemplateObject';
  body: Scalars['String']['output'];
  /** reach text body */
  bodyJson: Maybe<Array<Scalars['JSONObject']['output']>>;
  icon: Maybe<Scalars['String']['output']>;
  id: Scalars['ObjectID']['output'];
  language: Languages;
  subject: Scalars['String']['output'];
}

export interface TemplateVariablesObject {
  __typename?: 'TemplateVariablesObject';
  agent: Array<Scalars['String']['output']>;
  customer: Array<Scalars['String']['output']>;
  order: Array<Scalars['String']['output']>;
  return: Array<Scalars['String']['output']>;
}

export interface TemplateWithVariablesObject {
  __typename?: 'TemplateWithVariablesObject';
  body: Scalars['String']['output'];
  /** reach text body */
  bodyJson: Maybe<Array<Scalars['JSONObject']['output']>>;
}

export enum TicketChannels {
  Chat = 'chat',
  Email = 'email',
  Facebook = 'facebook',
  FacebookComment = 'facebookComment',
  Instagram = 'instagram',
  InstagramComment = 'instagramComment',
  Support = 'support',
  Voice = 'voice',
  Whatsapp = 'whatsapp',
}

export interface TicketCommentInput {
  /** Attachments to comment */
  attachments: InputMaybe<Array<AttachmentInput>>;
  /** List of agents ids that has been mentioned in the comment */
  mentions: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Comment body text */
  text: Array<Scalars['JSONObject']['input']>;
  /** Ticket id */
  ticketId: Scalars['ID']['input'];
}

export interface TicketCreateByAgentInput {
  attachments: InputMaybe<Array<AttachmentInput>>;
  /** reach editor body */
  bodyJson: Array<Scalars['JSONObject']['input']>;
  cc: InputMaybe<Array<Scalars['String']['input']>>;
  emailConfiguration: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<TicketOrderInput>;
  recipientEmail: Scalars['String']['input'];
  recipientName: Scalars['String']['input'];
  snoozeTimeUnit: InputMaybe<TimeUnit>;
  snoozeTimeValue: InputMaybe<Scalars['Float']['input']>;
  status: InputMaybe<TicketStatuses>;
  subject: Scalars['String']['input'];
  type: InputMaybe<TicketTypes>;
}

export interface TicketCreateBySupportInput {
  attachments: InputMaybe<Array<AttachmentInput>>;
  body: Scalars['String']['input'];
  subject: Scalars['String']['input'];
  supportSubject: Scalars['String']['input'];
}

export interface TicketDraftInput {
  attachments: InputMaybe<Array<AttachmentInput>>;
  /** reach editor body */
  textJson: InputMaybe<Array<Scalars['JSONObject']['input']>>;
  ticketId: Scalars['String']['input'];
}

export interface TicketDraftObject {
  __typename?: 'TicketDraftObject';
  attachments: Maybe<Array<MessageAttachmentObject>>;
  text: Maybe<Scalars['String']['output']>;
  /** reach text body */
  textJson: Maybe<Array<Scalars['JSONObject']['output']>>;
}

export interface TicketLogObject {
  __typename?: 'TicketLogObject';
  assignee: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  rating: Maybe<Scalars['Float']['output']>;
  status: Maybe<TicketStatuses>;
  tags: Maybe<Array<Scalars['String']['output']>>;
  triggeredByChat: Scalars['Boolean']['output'];
  triggeredByUser: Maybe<Scalars['String']['output']>;
  type: TicketLogType;
}

export enum TicketLogType {
  ChatDisconnected = 'chatDisconnected',
  FirstReplyByAgent = 'firstReplyByAgent',
  TicketAssigned = 'ticketAssigned',
  TicketRating = 'ticketRating',
  TicketResolution = 'ticketResolution',
  TicketStatus = 'ticketStatus',
  TicketTagged = 'ticketTagged',
  TicketUntagged = 'ticketUntagged',
}

export interface TicketObject {
  __typename?: 'TicketObject';
  assignee: Maybe<Scalars['ObjectID']['output']>;
  brand: Maybe<Scalars['ObjectID']['output']>;
  callId: Maybe<Array<Scalars['String']['output']>>;
  cc: Maybe<Array<Scalars['String']['output']>>;
  channel: TicketChannels;
  clientStore: Maybe<Scalars['String']['output']>;
  closedAt: Maybe<Scalars['DateTime']['output']>;
  closedBy: Maybe<Scalars['ObjectID']['output']>;
  courierType: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Maybe<Scalars['ObjectID']['output']>;
  /** Customer related tickets */
  customerRelatedTickets: Array<Maybe<TicketObject>>;
  draft: Maybe<TicketDraftObject>;
  hasBeenSeenByAgent: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ObjectID']['output'];
  language: Maybe<Scalars['String']['output']>;
  lastRecipientResponseDate: Maybe<Scalars['DateTime']['output']>;
  manipulated: Scalars['Boolean']['output'];
  messagePreview: Scalars['String']['output'];
  messages: Maybe<Array<Maybe<MessageObject>>>;
  order: Maybe<TicketOrderObject>;
  orders: Maybe<Array<TicketOrderObject>>;
  post: Maybe<TicketPostObject>;
  rating: Maybe<Scalars['Float']['output']>;
  recipientEmail: Maybe<Scalars['String']['output']>;
  recipientId: Maybe<Scalars['String']['output']>;
  recipientName: Scalars['String']['output'];
  recipientPhoto: Maybe<Scalars['String']['output']>;
  relatedTickets: Maybe<Array<Maybe<TicketObject>>>;
  returnId: Maybe<Scalars['String']['output']>;
  review: Maybe<Scalars['Float']['output']>;
  reviewComment: Maybe<Scalars['String']['output']>;
  status: TicketStatuses;
  subject: Scalars['String']['output'];
  supportEmail: Scalars['String']['output'];
  supportStatus: Maybe<TicketSupportStatuses>;
  supportSubject: Maybe<Scalars['String']['output']>;
  tags: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ticketId: Scalars['String']['output'];
  type: TicketTypes;
  updatedAt: Scalars['DateTime']['output'];
  waitingForRecipientResponse: Scalars['Boolean']['output'];
  watchers: Maybe<Array<Maybe<Scalars['ObjectID']['output']>>>;
}

export interface TicketOrderInput {
  _id: Scalars['String']['input'];
  courierName: Scalars['String']['input'];
  id: Scalars['String']['input'];
  otn: Scalars['String']['input'];
}

export interface TicketOrderObject {
  __typename?: 'TicketOrderObject';
  _id: Scalars['ObjectID']['output'];
  courierName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  otn: Scalars['String']['output'];
}

export interface TicketPostObject {
  __typename?: 'TicketPostObject';
  attachments: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  body: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
}

export interface TicketSetReviewInput {
  review: Scalars['Float']['input'];
  reviewComment: InputMaybe<Scalars['String']['input']>;
  ticketId: Scalars['String']['input'];
}

export enum TicketStatuses {
  Closed = 'closed',
  Open = 'open',
  Pending = 'pending',
  Snoozed = 'snoozed',
}

export enum TicketSupportStatuses {
  ActionNeeded = 'actionNeeded',
  InProgress = 'inProgress',
  Solved = 'solved',
}

export enum TicketTypes {
  Claim = 'claim',
  Courier = 'courier',
  Customer = 'customer',
  Onboarding = 'onboarding',
  Return = 'return',
  Support = 'support',
}

export enum TimeUnit {
  Day = 'day',
  Hour = 'hour',
}

export interface TranslationValuesInput {
  agentQuestion: InputMaybe<Scalars['String']['input']>;
  endAgentEndsChat: InputMaybe<Scalars['String']['input']>;
  endCancelButton: InputMaybe<Scalars['String']['input']>;
  endChatButton: InputMaybe<Scalars['String']['input']>;
  endText: InputMaybe<Scalars['String']['input']>;
  endTitle: InputMaybe<Scalars['String']['input']>;
  findOrderFeedback: InputMaybe<Scalars['String']['input']>;
  findOrderNotFound: InputMaybe<Scalars['String']['input']>;
  findOrderOrderId: InputMaybe<Scalars['String']['input']>;
  findOrderPostcode: InputMaybe<Scalars['String']['input']>;
  findOrderQuestion: InputMaybe<Scalars['String']['input']>;
  findOrderThanks: InputMaybe<Scalars['String']['input']>;
  findOrderTrack: InputMaybe<Scalars['String']['input']>;
  findOrderTryAgain: InputMaybe<Scalars['String']['input']>;
  greeting: InputMaybe<Scalars['String']['input']>;
  initialMessages: InputMaybe<Array<Scalars['String']['input']>>;
  introduction: InputMaybe<Scalars['String']['input']>;
  offlineEmail: InputMaybe<Scalars['String']['input']>;
  offlineMessage: InputMaybe<Scalars['String']['input']>;
  offlineName: InputMaybe<Scalars['String']['input']>;
  offlineSendMessage: InputMaybe<Scalars['String']['input']>;
  offlineStatus: InputMaybe<Scalars['String']['input']>;
  offlineText: InputMaybe<Scalars['String']['input']>;
  offlineTitle: InputMaybe<Scalars['String']['input']>;
  onlineEmail: InputMaybe<Scalars['String']['input']>;
  onlineName: InputMaybe<Scalars['String']['input']>;
  onlineReplyHere: InputMaybe<Scalars['String']['input']>;
  onlineStartButton: InputMaybe<Scalars['String']['input']>;
  onlineStatus: InputMaybe<Scalars['String']['input']>;
  onlineText: InputMaybe<Scalars['String']['input']>;
  onlineTitle: InputMaybe<Scalars['String']['input']>;
  returnAnswer: InputMaybe<Scalars['String']['input']>;
  returnAnswerFeedback: InputMaybe<Scalars['String']['input']>;
  returnAnswerThanks: InputMaybe<Scalars['String']['input']>;
  returnQuestion: InputMaybe<Scalars['String']['input']>;
  secondaryMessages: InputMaybe<Array<Scalars['String']['input']>>;
  thankYouText: InputMaybe<Scalars['String']['input']>;
  thankYouTitle: InputMaybe<Scalars['String']['input']>;
}

export interface TranslationValuesObject {
  __typename?: 'TranslationValuesObject';
  agentQuestion: Maybe<Scalars['String']['output']>;
  endAgentEndsChat: Maybe<Scalars['String']['output']>;
  endCancelButton: Maybe<Scalars['String']['output']>;
  endChatButton: Maybe<Scalars['String']['output']>;
  endText: Maybe<Scalars['String']['output']>;
  endTitle: Maybe<Scalars['String']['output']>;
  findOrderFeedback: Maybe<Scalars['String']['output']>;
  findOrderNotFound: Maybe<Scalars['String']['output']>;
  findOrderOrderId: Maybe<Scalars['String']['output']>;
  findOrderPostcode: Maybe<Scalars['String']['output']>;
  findOrderQuestion: Maybe<Scalars['String']['output']>;
  findOrderThanks: Maybe<Scalars['String']['output']>;
  findOrderTrack: Maybe<Scalars['String']['output']>;
  findOrderTryAgain: Maybe<Scalars['String']['output']>;
  greeting: Maybe<Scalars['String']['output']>;
  initialMessages: Maybe<Array<Scalars['String']['output']>>;
  introduction: Maybe<Scalars['String']['output']>;
  offlineEmail: Maybe<Scalars['String']['output']>;
  offlineMessage: Maybe<Scalars['String']['output']>;
  offlineName: Maybe<Scalars['String']['output']>;
  offlineSendMessage: Maybe<Scalars['String']['output']>;
  offlineStatus: Maybe<Scalars['String']['output']>;
  offlineText: Maybe<Scalars['String']['output']>;
  offlineTitle: Maybe<Scalars['String']['output']>;
  onlineEmail: Maybe<Scalars['String']['output']>;
  onlineName: Maybe<Scalars['String']['output']>;
  onlineReplyHere: Maybe<Scalars['String']['output']>;
  onlineStartButton: Maybe<Scalars['String']['output']>;
  onlineStatus: Maybe<Scalars['String']['output']>;
  onlineText: Maybe<Scalars['String']['output']>;
  onlineTitle: Maybe<Scalars['String']['output']>;
  returnAnswer: Maybe<Scalars['String']['output']>;
  returnAnswerFeedback: Maybe<Scalars['String']['output']>;
  returnAnswerThanks: Maybe<Scalars['String']['output']>;
  returnQuestion: Maybe<Scalars['String']['output']>;
  secondaryMessages: Maybe<Array<Scalars['String']['output']>>;
  thankYouText: Maybe<Scalars['String']['output']>;
  thankYouTitle: Maybe<Scalars['String']['output']>;
}

export interface UpdateCompanyFolderInput {
  brands: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  channels: InputMaybe<Array<InputMaybe<TicketChannels>>>;
  icon: InputMaybe<Scalars['String']['input']>;
  languages: InputMaybe<Array<InputMaybe<Languages>>>;
  name: InputMaybe<Scalars['String']['input']>;
  tags: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ticketAssignees: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  visibleFor: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export interface UpdatePersonalFolderInput {
  brands: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  channels: InputMaybe<Array<InputMaybe<TicketChannels>>>;
  icon: InputMaybe<Scalars['String']['input']>;
  languages: InputMaybe<Array<InputMaybe<Languages>>>;
  name: InputMaybe<Scalars['String']['input']>;
  tags: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export interface VoiceObject {
  __typename?: 'VoiceObject';
  success: Scalars['Boolean']['output'];
}

export interface VoicePhoneNumberObject {
  __typename?: 'VoicePhoneNumberObject';
  brandId: Scalars['String']['output'];
  phone: Scalars['String']['output'];
}

export enum WhenTimeOptions {
  Immediate = 'Immediate',
  TimeFrame = 'TimeFrame',
}
