import React, { FC, memo } from 'react';

interface TrackingNumberProps {
  trackingNumber?: string;
  secondaryTrackingNumber?: string | null;
  courier: string;
}

const SHOW_BOTH_COURIERS = ['nacex', 'envialia'];
const TrackingNumberComponent: FC<TrackingNumberProps> = (props: TrackingNumberProps) => {
  const { trackingNumber, secondaryTrackingNumber, courier } = props;

  if (secondaryTrackingNumber && SHOW_BOTH_COURIERS.includes(courier.toLowerCase())) {
    return (
      <span className="w-max text-xl font-semibold text-tp-primaryText">
        {trackingNumber} | {secondaryTrackingNumber}
      </span>
    );
  }
  return (
    (trackingNumber && (
      <span className="w-max text-xl font-semibold text-tp-primaryText">{trackingNumber}</span>
    )) || <span className="w-max text-xl font-semibold text-tp-primaryText">-</span>
  );
};

export default memo<TrackingNumberProps>(TrackingNumberComponent);
