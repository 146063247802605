import React, { forwardRef } from 'react';

import { Tooltip } from '@material-ui/core';
import { OutvioSmall } from 'icons';
import { useIntl } from 'localization';
import { twMerge } from 'tailwind-merge';
import { useCourierIcon } from 'ui/hooks';

interface ICourierIcon {
  courier: string;
  height?: number;
  className?: string;
  isCustom?: boolean;
  style?: any;
  method?: string;
  disableTooltip?: boolean;
}

const CourierIcon = forwardRef<HTMLSpanElement, ICourierIcon>(
  (
    {
      courier = '',
      height = 15,
      className = '',
      isCustom = false,
      style = {},
      disableTooltip = false,
    },
    ref,
  ) => {
    const { data = [] } = useCourierIcon();

    const courierObj = data.find(
      (item) => item.integration.toLowerCase() === courier.toLowerCase(),
    );

    const { formatMessage } = useIntl();

    return (
      <Tooltip
        disableFocusListener={disableTooltip}
        disableHoverListener={disableTooltip}
        disableTouchListener={disableTooltip}
        title={`${formatMessage({ id: `general.courier.${courier.toLowerCase()}` })}${
          !isCustom ? ' (Outvio)' : ''
        }`}
        placement="top"
      >
        <span
          className={twMerge(
            'inline-flex items-center justify-center mx-auto h-full text-teal text-2xl gap-1',
            className,
          )}
        >
          <span
            style={{
              ...style,
              height: `${height}px`,
            }}
            ref={ref}
            className="max-h-full max-w-full flex items-center justify-center"
          >
            <img
              className="max-h-full max-w-full w-full"
              src={courierObj?.logo}
              height={height}
              alt={courierObj?.name}
            />
          </span>
          {!isCustom && (
            <span className="h-2/5 self-start">
              <img src={OutvioSmall} className="h-full" alt="Outvio logo" />
            </span>
          )}
        </span>
      </Tooltip>
    );
  },
);

export { CourierIcon };
