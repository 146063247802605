import React, { forwardRef, useContext, useMemo } from 'react';

import { Theme, makeStyles, useMediaQuery } from '@material-ui/core';

import OVCStoreDataContext from './OVCStoreDataContext';

const useStyles = makeStyles({
  hero: {
    width: '100%',
    height: '630px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    backgroundPositionX: 'center',
    backgroundPositionY: 'center',
    backgroundSize: 'cover',
    backgroundRepeatX: 'no-repeat',
    backgroundRepeatY: 'no-repeat',
    backgroundAttachment: 'local',
  },
});

interface IOVCHeroImage {
  children: React.ReactNode;
  useTrackingImg?: boolean;
}

interface ImgKey {
  desktop: 'desktopBg' | 'trackingDesktopBg';
  mobile: 'mobileBg' | 'trackingMobileBg';
}

const OVCHeroImage = forwardRef<HTMLDivElement, IOVCHeroImage>(
  ({ children, useTrackingImg = false }, ref) => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'), { noSsr: true });
    const context = useContext(OVCStoreDataContext);

    const imgKey: ImgKey = useMemo(
      () =>
        useTrackingImg
          ? { desktop: 'trackingDesktopBg', mobile: 'trackingMobileBg' }
          : { desktop: 'desktopBg', mobile: 'mobileBg' },
      [useTrackingImg],
    );

    const classes = useStyles();

    const containerStyle: React.CSSProperties = useMemo(
      () => ({
        backgroundImage: `url(${
          context.storeData.portalSettings[imgKey[isMobile ? 'mobile' : 'desktop']]
        })`,
      }),
      [context.storeData, useTrackingImg],
    );

    return (
      <div className={classes.hero} style={containerStyle} ref={ref}>
        {children}
      </div>
    );
  },
);

export default OVCHeroImage;
