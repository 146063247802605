import { ElementType, HTMLAttributes, ReactNode } from 'react';

import { Pending } from 'icons';
import { twMerge } from 'tailwind-merge';

import './Button.css';

export enum ButtonVariant {
  Primary = 'primary',
  PrimaryBordered = 'primaryBordered',
  Secondary = 'secondary',
  Facebook = 'facebook',
  WhatsApp = 'whatsApp',
  Danger = 'danger',
  Info = 'info',
  violet = 'violet',
  green = 'green',
  orange = 'orange',
  blue = 'blue',
  blueBordered = 'blueBordered',
}

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  theme?: ButtonVariant;
  icon?: ReactNode;
  as?: ElementType;
  iconPosition?: 'left' | 'right';
  loading?: boolean;
  href?: string;
  target?: string;
  disabled?: boolean;
  iconclass?: string;
  iconWrapperClass?: string;
  onClickIcon?: () => void;
  type?: 'submit' | 'reset' | 'button' | undefined;
}

const defaultTag = 'button';

function Button({
  className,
  iconclass,
  iconWrapperClass,
  as: Tag = defaultTag,
  icon: _icon,
  theme = ButtonVariant.Primary,
  iconPosition = 'left',
  onClickIcon,
  loading,
  children,
  disabled,
  ...otherProps
}: ButtonProps) {
  const icon =
    _icon || loading ? (
      <div
        className={twMerge('icon text-v2blueGray-500', `icon-${iconPosition}`, iconWrapperClass)}
        onClick={(evt) => {
          evt?.preventDefault?.();
          evt?.stopPropagation?.();
          onClickIcon?.();
        }}
      >
        {iconPosition !== 'left' && <div className={twMerge('icon-divider', iconclass)} />}
        {_icon && !loading ? _icon : <Pending className="animate-spin" width="24" height="24" />}
      </div>
    ) : null;

  return (
    <Tag
      className={twMerge(
        'button select-none',
        loading && 'loading',
        `button-${theme}`,
        disabled && Tag !== defaultTag && 'disabled',
        icon && `button-icon-${iconPosition}`,
        className,
      )}
      disabled={disabled || loading}
      {...otherProps}
    >
      <div className="whitespace-nowrap font-medium">{children}</div>
      {icon}
    </Tag>
  );
}

export default Button;
