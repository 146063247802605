import React, { ButtonHTMLAttributes, forwardRef, useContext } from 'react';

import { CircularProgress, Theme, makeStyles } from '@material-ui/core';
import cn from 'classnames';

import OVCStoreDataContext from './OVCStoreDataContext';

interface StyleProps {
  bgColor: string;
  textColor: string;
}

const useStyles = makeStyles<Theme, StyleProps>({
  base: {
    position: 'relative',
    height: '100%',
    width: 'fit-content',
    padding: '9px 30px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 600,
    textAlign: 'center',
    textTransform: 'uppercase',

    boxSizing: 'border-box',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: (props) => props.bgColor,
    color: (props) => props.textColor,

    outline: 'none',

    '&:hover': {
      cursor: 'pointer',
    },

    '&:disabled:hover': {
      cursor: 'not-allowed',
    },
  },
  inverted: {
    border: (props) => `1px solid ${props.bgColor}`,
    backgroundColor: (props) => props.textColor,
    color: (props) => props.bgColor,
  },
  fullWidth: {
    width: '100%',
  },
  hasIcon: {
    padding: '9px 30px 9px 47px',
  },
  hasIconOnRight: {
    padding: '9px 47px 9px 30px',
  },
  iconHolder: {
    position: 'absolute',
    top: 0,
    left: '9px',
    bottom: 0,
    width: '17px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '17px',
  },
  iconHolderRight: {
    left: 'unset',
    right: '9px',
  },
});

export interface IOVCButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  fullWidth?: boolean;
  useAlert?: boolean;
  showLoader?: boolean;
  icon?: React.ReactNode;
  iconOnRight?: boolean;
  inverted?: boolean;
}

const colorWithTrackingPageV2Fallback = (colorV2?: string, colorV1?: string) =>
  colorV2 || colorV1 || '#4bb4b3'; // Add the fallback color

const OVCButton = forwardRef<HTMLButtonElement, IOVCButton>(
  (
    {
      children,
      className,
      fullWidth = false,
      useAlert = false,
      showLoader = false,
      icon = null,
      iconOnRight = false,
      inverted = false,
      ...rest
    },
    ref,
  ) => {
    const { storeData } = useContext(OVCStoreDataContext);

    const classes = useStyles({
      bgColor: useAlert
        ? colorWithTrackingPageV2Fallback(
            storeData?.portalV2?.colors?.alertColor,
            storeData?.portalSettings?.colors?.alertColor,
          )
        : colorWithTrackingPageV2Fallback(
            storeData?.portalV2?.colors?.btnBg,
            storeData?.portalSettings?.colors?.btnBg,
          ),
      textColor: useAlert
        ? colorWithTrackingPageV2Fallback(
            storeData?.portalV2?.colors?.secondaryBg,
            storeData?.portalSettings?.colors?.secondaryBg,
          )
        : colorWithTrackingPageV2Fallback(
            storeData?.portalV2?.colors?.btnText,
            storeData?.portalSettings?.colors?.btnText,
          ),
    });
    return (
      <button
        className={cn(
          classes.base,
          {
            [classes.fullWidth]: fullWidth,
            [classes.hasIcon]: (showLoader || icon !== null) && !iconOnRight,
            [classes.hasIconOnRight]: (showLoader || icon !== null) && iconOnRight,
            [classes.inverted]: inverted,
          },
          className,
        )}
        {...rest}
        ref={ref}
      >
        {showLoader && (
          <div className={cn(classes.iconHolder, { [classes.iconHolderRight]: iconOnRight })}>
            <CircularProgress color="inherit" size={15} />
          </div>
        )}
        {!showLoader && icon !== null && (
          <div className={cn(classes.iconHolder, { [classes.iconHolderRight]: iconOnRight })}>
            {icon}
          </div>
        )}
        {children}
      </button>
    );
  },
);

export default OVCButton;
