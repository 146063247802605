import React, { FC, useContext, useState } from 'react';

import { Field, Form, Formik } from 'formik';

import TrackingPageContext from '../../../Context/TrackingPageContext';
import { ButtonTypes } from '../../../Interfaces/IButton';
import { Emojis } from '../../../Interfaces/ITrackingData';
import BaseModal from '../../Common/BaseModal';
import Button from '../../Common/Button';
import RatingInput from '../../Common/Inputs/RatingInput';

interface RatingModal {
  isRatingModalVisible: boolean;
  toggleRatingModal(value: boolean): void;
  handleRate?(rating: number): void;
}

const ratingValues: { value: number; emoji: Emojis }[] = [
  { value: 1, emoji: Emojis.SAD },
  { value: 2, emoji: Emojis.MKAY },
  { value: 3, emoji: Emojis.HAPPY },
  { value: 4, emoji: Emojis.BIG_SMILE },
  { value: 5, emoji: Emojis.STARRY_EYES },
];

const initialValues: { rating?: number } = {
  rating: undefined,
};

const RatingModal: FC<RatingModal> = (props) => {
  const {
    portalSettings: {
      regionalSettings: {
        rating: { rateButton, askLater, thanks, body },
      },
    },
  } = useContext(TrackingPageContext);
  const { isRatingModalVisible: isVisible, toggleRatingModal: setIsVisible, handleRate } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = (values: { rating?: number }): Promise<boolean> => {
    setIsSubmitting(true);
    return new Promise((resolve) => {
      if (handleRate && values.rating) handleRate(values.rating);
      setTimeout(() => resolve(true), 1000);
    });
  };
  return (
    <BaseModal
      isVisible={isVisible}
      handleClose={() => setIsVisible(false)}
      withTitleBar={false}
      iframeHeight="0px"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => handleSubmit(values).finally(() => setIsVisible(false))}
      >
        {({ dirty }) => (
          <Form className="flex flex-col space-y-5 md:w-max md:mx-24">
            {!isSubmitting && (
              <>
                <span className="text-tp-primaryText text-xl">{body}</span>
                <div className="flex h-[64px] items-center mx-auto space-x-4">
                  {ratingValues.map(({ value, emoji }) => (
                    <Field
                      name="rating"
                      component={RatingInput}
                      emoji={emoji}
                      val={value}
                      key={`${emoji}-${value}`}
                    />
                  ))}
                </div>
                <div className="flex space-x-4 mx-auto">
                  <Button
                    type={ButtonTypes.SECONDARY}
                    content={askLater}
                    onClick={() => setIsVisible(false)}
                  />
                  <Button
                    type={ButtonTypes.MAIN}
                    isDisabled={!dirty}
                    isSubmit
                    content={rateButton}
                  />
                </div>
              </>
            )}
            {isSubmitting && <span>{thanks}</span>}
          </Form>
        )}
      </Formik>
    </BaseModal>
  );
};

export default RatingModal;
