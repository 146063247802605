import React, { useContext } from 'react';

import { Theme, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'localization';

import OVCOutvioLogoBig from './OVCOutvioLogoBig';
import OVCStoreDataContext from './OVCStoreDataContext';

interface StyleProps {
  textColor: string;
}

const useStyles = makeStyles<Theme, StyleProps>({
  footer: {
    width: '100%',
    height: '70px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    fontSize: '14px',
    color: (props) => props.textColor,

    '& > *': {
      marginRight: '4px',

      '&:last-child': {
        marginRight: 0,
      },
    },

    '& svg': {
      height: '18px',
    },
  },
});

const OVCFooterWatermark = () => {
  const context = useContext(OVCStoreDataContext);
  const classes = useStyles({ textColor: context.storeData.portalSettings.colors.primaryText });

  if (context.storeData.feature.customerPortal.watermark) {
    return (
      <div className={classes.footer} data-cy="ovc-layout-watermark">
        <FormattedMessage id="ovc.general.powered-by" />
        <OVCOutvioLogoBig />
      </div>
    );
  }

  return null;
};

export default OVCFooterWatermark;
