import React, { useContext } from 'react';

import { Theme, makeStyles } from '@material-ui/core';

import OVCStoreDataContext from './OVCStoreDataContext';

interface StyleProps {
  backgroundColor: string;
  color?: string;
}

const useStyles = makeStyles<Theme, StyleProps>({
  container: {
    width: '100%',
    minHeight: '100vh',

    backgroundColor: (props) => props.backgroundColor,
    color: (props) => props.color,
  },
});

const OVCLayoutContainer = ({ children }: { children: React.ReactNode }) => {
  const {
    storeData: {
      portalSettings: {
        colors: { primaryBg },
      },
      portalV2,
      featureFlags,
    },
  } = useContext(OVCStoreDataContext);
  const classes = useStyles({
    backgroundColor: featureFlags?.newTrackingPage ? portalV2?.colors.primaryBg : primaryBg,
    color: featureFlags?.newTrackingPage ? portalV2?.colors.primaryText : undefined,
  });

  return (
    <div className={classes.container} data-cy="ovc-layout-container">
      {children}
    </div>
  );
};

export default OVCLayoutContainer;
