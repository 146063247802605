import React, { HTMLAttributes, useContext, useMemo } from 'react';

import { Collapse, Theme, makeStyles } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/WarningRounded';
import cn from 'classnames';

import OVCStoreDataContext from './OVCStoreDataContext';

interface StyleProps {
  alertColor: string;
  textColor: string;
  btnBg: string;
  btnText: string;
}

const useStyles = makeStyles<Theme, StyleProps>({
  container: {
    position: 'relative',
    width: '100%',
    padding: '8px 12px',

    display: 'grid',
    gridTemplateColumns: '24px 1fr',
    gridColumnGap: '12px',

    borderRadius: '5px',

    fontSize: '11px',
    color: (props) => props.textColor,
  },
  containerInfo: {
    backgroundColor: (props) => props.btnBg,
    color: (props) => props.btnText,
  },
  containerWarning: {
    backgroundColor: (props) => props.alertColor,
  },
  containerDanger: {
    backgroundColor: (props) => props.alertColor,
  },
  iconHolder: {
    paddingTop: '2px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',

    color: (props) => props.btnText,
    fontSize: '20px',
  },
  iconHolderDanger: {
    color: (props) => props.textColor,
  },
  iconHolderWarning: {
    color: (props) => props.textColor,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    '& p': {
      margin: 0,
    },
  },
});

interface IOVCAlert extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  type?: 'danger' | 'info' | 'warning';
  isOpen?: boolean;
  style?: any;
}

const OVCAlert = ({
  children,
  type = 'danger',
  isOpen = true,
  style = {},
  ...restProps
}: IOVCAlert) => {
  const { storeData } = useContext(OVCStoreDataContext);
  const classes = useStyles({
    alertColor: storeData.portalSettings.colors.alertColor,
    textColor: storeData.portalSettings.colors.primaryText,
    btnBg: storeData.portalSettings.colors.btnBg,
    btnText: storeData.portalSettings.colors.btnText,
  });

  const elClassName = useMemo(
    () =>
      cn(classes.container, {
        [classes.containerDanger]: type === 'danger',
        [classes.containerInfo]: type === 'info',
        [classes.containerWarning]: type === 'warning',
      }),
    [classes, type],
  );

  const iconClassName = useMemo(
    () =>
      cn(classes.iconHolder, {
        [classes.iconHolderDanger]: type === 'danger',
        [classes.iconHolderWarning]: type === 'warning',
      }),
    [classes, type],
  );

  return (
    <Collapse in={isOpen} style={{ width: '100%' }}>
      <div className={elClassName} style={style} {...restProps}>
        <div className={iconClassName}>
          <WarningIcon color="inherit" fontSize="inherit" />
        </div>
        <div className={classes.content}>{children}</div>
      </div>
    </Collapse>
  );
};

export default OVCAlert;
