import { ReactNode, memo } from 'react';

import { twMerge } from 'tailwind-merge';

interface IAlertContainer {
  children: ReactNode;
  color?: 'blue' | 'blue-600' | 'orange' | 'white' | 'gray' | 'green' | 'primary' | 'red';
  className?: string;
}

export function AlertContainer({ children, color = 'blue', className }: IAlertContainer) {
  return (
    <div
      className={twMerge(
        'py-6 px-8 rounded-[14px]',
        color === 'blue' && 'bg-v2blue/20 text-v2blue',
        color === 'blue-600' && 'bg-v2blue-600/20 text-v2blue-600/80',
        color === 'orange' && 'py-8 px-6 bg-v2orange/20 color-v2blue',
        color === 'white' && 'p-6 bg-white border border-v2blueGray-200',
        color === 'gray' && 'bg-v2blueGray-100',
        color === 'green' && 'bg-v2green/20 text-v2green',
        color === 'primary' && 'bg-primary/20 text-primary',
        color === 'red' && 'bg-v2red/20 text-v2red',
        className,
      )}
    >
      {children}
    </div>
  );
}

export default memo<IAlertContainer>(AlertContainer);
