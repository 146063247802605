import React from 'react';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  header: {
    position: 'relative',
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const OVCLayoutHeader = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();

  return (
    <div className={classes.header} data-cy="ovc-layout-header">
      {children}
    </div>
  );
};

export default OVCLayoutHeader;
