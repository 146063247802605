import React, { Suspense, lazy, useEffect, useState } from 'react';

import { QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { ApolloProvider } from '@apollo/client';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { apolloClient } from 'api/apolloClient';
import { BRAND_FIND_BY_URI_QUERY } from 'api/queries/queries';
import { queryClient } from 'api/queryClient';
import { DomainDataObject } from 'app-types/returns';
import { Loading } from 'components/Loading';
import { PortalAlert } from 'return-portal-ui';

import './index.css';
import store from './oldPortal/src/store';

const App = lazy(() => import('./App'));
const AppTracking = lazy(() => import('./oldPortal/src/AppTracking'));

const theme = createTheme({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: { xs: 0, sm: 600, md: 960, lg: 1280, xl: 1920 },
  },
  typography: {
    fontSize: 13,
  },
});

const Apps = () => {
  const [isTracking, setIsTracking] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    apolloClient
      .query<{ data: DomainDataObject }>({ query: BRAND_FIND_BY_URI_QUERY })
      .then((result) => {
        setIsTracking(result?.data?.data?.isTracking);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log('BRAND_FIND_BY_URI_QUERY', error);
        setError(error);
      });
  }, []);

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen w-full">
        <PortalAlert>{error?.message}</PortalAlert>
      </div>
    );
  }

  if (isLoading) {
    return <Loading />;
  }
  return (
    <div className="root-wrap">
      {/* @ts-ignore */}
      <QueryClientProvider client={queryClient}>
        <ApolloProvider client={apolloClient}>
          {isTracking ? (
            <Provider store={store}>
              <MuiThemeProvider theme={theme}>
                <BrowserRouter>
                  <Suspense fallback={<Loading />}>
                    <AppTracking />
                  </Suspense>
                </BrowserRouter>
              </MuiThemeProvider>
            </Provider>
          ) : (
            <BrowserRouter>
              <Suspense fallback={<Loading />}>
                <App />
              </Suspense>
            </BrowserRouter>
          )}
        </ApolloProvider>
      </QueryClientProvider>
    </div>
  );
};

export { Apps };
