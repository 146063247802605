import React, { InputHTMLAttributes, useContext, useMemo } from 'react';

import { Theme, makeStyles } from '@material-ui/core';
import cn from 'classnames';

import opacityToSolidColor from '../utils/opacityToSolidColor';
import OVCStoreDataContext from './OVCStoreDataContext';

interface StyleProps {
  textColor: string;
  alertColor: string;
  fadeTextColor: string;
}

const useStyles = makeStyles<Theme, StyleProps>({
  base: {
    width: '100%',
    height: '40px',
    padding: '0 20px',

    borderRadius: '5px',
    border: (props) => `1px solid ${props.textColor}`,
    backgroundColor: 'transparent',
    color: (props) => props.textColor,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',

    outline: 'none',

    fontSize: '16px',
    lineHeight: '22px',

    '&::placeholder': {
      color: (props: any) => props.fadeTextColor,
    },

    '&:disabled': {
      cursor: 'not-allowed',
    },
  },
  baseError: {
    borderColor: (props) => props.alertColor,
  },
});

export interface IOVCInput extends InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  useSecondaryBg?: boolean;
}

const OVCInput = ({
  error = false,
  className = '',
  useSecondaryBg = false,
  ...rest
}: IOVCInput) => {
  const { storeData } = useContext(OVCStoreDataContext);
  const fadeTextColor = useMemo(
    () =>
      opacityToSolidColor(
        storeData.portalSettings.colors.primaryText,
        0.7,
        storeData.portalSettings.colors[useSecondaryBg ? 'secondaryBg' : 'primaryBg'],
      ),
    [storeData],
  );
  const classes = useStyles({
    textColor: storeData.portalSettings.colors.primaryText,
    fadeTextColor: fadeTextColor,
    alertColor: storeData.portalSettings.colors.alertColor,
  });

  return (
    <input className={cn(classes.base, { [classes.baseError]: error }, className)} {...rest} />
  );
};

export default OVCInput;
