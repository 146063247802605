import { Fragment, ReactNode } from 'react';

import { type Placement, flip, offset, shift, size, useFloating } from '@floating-ui/react-dom';
import { Menu, Transition } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { Button, ButtonVariant } from 'ui/atoms';

import { TPopupPositions } from '../Dropdown/DropdownPopup';
import { useDropdownToggle } from './DropdownButton.hooks';
import IconDropdown from './IconDropdown';

export interface Option {
  label: ReactNode;
  onClick?: (item?: Option) => void;
  icon?: ReactNode;
  value?: any;
}
interface Props {
  position?: TPopupPositions;
  isDoubleActionDisabled?: boolean;
  children: ReactNode;
  options: Option[];
  theme: ButtonVariant;
  buttonClass?: string;
  iconWrapperClass?: string;
  iconclass?: string;
  onClick?: () => void;
  menuClass?: string;
  disabled?: boolean;
}
function DropdownButton({
  menuClass,
  position = 'top-start',
  children,
  options,
  theme,
  onClick,
  buttonClass,
  iconWrapperClass,
  iconclass,
  isDoubleActionDisabled = false,
  disabled,
}: Props) {
  const { uuidRef, isOpen, setIsOpen, toggleDropdown } = useDropdownToggle();
  const { refs, floatingStyles } = useFloating({
    placement: position as Placement,
    middleware: [
      offset(4),
      flip(),
      shift(),
      size({
        apply({ availableWidth, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            maxWidth: `${availableWidth}px`,
            maxHeight: `${availableHeight - 24}px`,
          });
        },
      }),
    ],
  });

  return (
    <Menu
      ref={refs.setReference}
      as="div"
      data-id={uuidRef.current}
      className="relative inline-block text-left"
    >
      <div>
        <Button
          disabled={disabled}
          className={buttonClass}
          iconWrapperClass={iconWrapperClass}
          iconclass={iconclass}
          icon={
            <div
              onClick={(e) => {
                e?.preventDefault?.();
                e?.stopPropagation?.();
                toggleDropdown();
              }}
            >
              <IconDropdown
                className={twMerge(`ml-[6px] transform`, isOpen ? `rotate-180` : `rotate-0`)}
              />
            </div>
          }
          iconPosition="right"
          theme={theme}
          onClick={(e) => {
            if (isDoubleActionDisabled) {
              e?.preventDefault?.();
              e?.stopPropagation?.();
              toggleDropdown();
            }
            onClick?.();
          }}
        >
          {children}
        </Button>
      </div>
      <Transition
        as={Fragment}
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        show={isOpen}
      >
        <Menu.Items
          className={twMerge(
            'flex mt-2 w-56 origin-top-right bg-white shadow-lg ring-1 ring-v2blueGray-200 ring-opacity-100 rounded-[14px] overflow-hidden focus:outline-none z-[1]',
            menuClass,
            isOpen && 'z-[2]',
          )}
          ref={refs.setFloating}
          style={floatingStyles}
        >
          <div className="w-full py-1 overflow-auto">
            {options?.map?.((item: Option, i: number) => (
              <div className="py-1 px-2" key={i}>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={twMerge(
                        active && 'bg-v2blueGray-100',
                        `group flex w-full items-center rounded-lg px-4 py-2 text-sm text-v2blueGray-800 `,
                      )}
                      onClick={() => {
                        setIsOpen(false);
                        item.onClick?.(item);
                      }}
                    >
                      {item.label}
                    </button>
                  )}
                </Menu.Item>
              </div>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export { ButtonVariant };

export default DropdownButton;
